import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import LatestUpdatesDevices from "../../widgets/LatestUpdatesDevices";
import LatestUpdatesSystems from "../../widgets/LatestUpdatesSystems";
import LatestUpdatesBrowsers from "../../widgets/LatestUpdatesBrowsers";
import EventDetails from "../../widgets/EventDetails";

import ConferencesWidget from "../../widgets/ConferencesWidget";
import StatisticsChart from "../../widgets/StatisticsChart";

import * as dashboardStore from "../../store/ducks/dashboard.duck";

function Dashboard(props) {
    const { getStatConferencesCounts, total_counts, total, terminated, intl } = props;

    const eventData = {
        totalData: {
            label: intl.formatMessage({ id: "DASHBOARD.SUMMARY.TOTAL" }),
            amount: total
        },
        terminatedData: {
            label: intl.formatMessage({ id: "DASHBOARD.SUMMARY.TOTAL_TERMINATED" }),
            amount: terminated
        },
        upcomingData: {
            label: intl.formatMessage({ id: "DASHBOARD.SUMMARY.TOTAL_UPCOMING" }),
            amount: total ? total - terminated : 0
        }
    };

    const conferenceData = {
        totalData: {
            label: intl.formatMessage({ id: "EVENT.TYPE.CONFERENCE" }),
            amount: total_counts ? total_counts[0]["total_count"] : 0
        },
        terminatedData: {
            label: intl.formatMessage({ id: "DASHBOARD.SUMMARY.TERMINATED" }),
            amount: total_counts ? total_counts[0]["terminated"] : 0
        },
        upcomingData: {
            label: intl.formatMessage({ id: "DASHBOARD.SUMMARY.UPCOMING" }),
            amount: total_counts
                ? total_counts[0]["total_count"] - total_counts[0]["terminated"]
                : 0
        }
    };

    const meetingData = {
        totalData: {
            label: intl.formatMessage({ id: "EVENT.TYPE.MEETING" }),
            amount: total_counts ? total_counts[1]["total_count"] : 0
        },
        terminatedData: {
            label: intl.formatMessage({ id: "DASHBOARD.SUMMARY.TERMINATED" }),
            amount: total_counts ? total_counts[1]["terminated"] : 0
        },
        upcomingData: {
            label: intl.formatMessage({ id: "DASHBOARD.SUMMARY.UPCOMING" }),
            amount: total_counts
                ? total_counts[1]["total_count"] - total_counts[1]["terminated"]
                : 0
        }
    };

    const webinarData = {
        totalData: {
            label: intl.formatMessage({ id: "EVENT.TYPE.WEBINAR" }),
            amount: total_counts ? total_counts[2]["total_count"] : 0
        },
        terminatedData: {
            label: intl.formatMessage({ id: "DASHBOARD.SUMMARY.TERMINATED" }),
            amount: total_counts ? total_counts[2]["terminated"] : 0
        },
        upcomingData: {
            label: intl.formatMessage({ id: "DASHBOARD.SUMMARY.UPCOMING" }),
            amount: total_counts
                ? total_counts[2]["total_count"] - total_counts[2]["terminated"]
                : 0
        }
    };

    const webData = {
        totalData: {
            label: intl.formatMessage({ id: "EVENT.TYPE.WEB" }),
            amount: total_counts ? total_counts[3]["total_count"] : 0
        },
        terminatedData: {
            label: intl.formatMessage({ id: "DASHBOARD.SUMMARY.TERMINATED" }),
            amount: total_counts ? total_counts[3]["terminated"] : 0
        },
        upcomingData: {
            label: intl.formatMessage({ id: "DASHBOARD.SUMMARY.UPCOMING" }),
            amount: total_counts
                ? total_counts[3]["total_count"] - total_counts[3]["terminated"]
                : 0
        }
    };

    useEffect(() => {
        getStatConferencesCounts();
    }, [getStatConferencesCounts]);

    return (
        <>
            <Portlet>
                <PortletBody fit={true}>
                    <div className="row row-no-padding row-col-separator-xl">
                        <div className="col-xl-3">
                            <ConferencesWidget conferenceData={eventData} />
                        </div>
                        <div className="col-xl-9 row">
                            <div className="col-xl-3">
                                <ConferencesWidget conferenceData={conferenceData} />
                            </div>
                            <div className="col-xl-3">
                                <ConferencesWidget conferenceData={meetingData} />
                            </div>
                            <div className="col-xl-3">
                                <ConferencesWidget conferenceData={webinarData} />
                            </div>
                            <div className="col-xl-3">
                                <ConferencesWidget conferenceData={webData} />
                            </div>
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
            <div className="row">
                <div className="col-xl-12">
                    <Portlet fluidHeight={true}>
                        <PortletBody>
                            <StatisticsChart />
                        </PortletBody>
                    </Portlet>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4">
                    <LatestUpdatesDevices />
                </div>
                <div className="col-xl-4">
                    <LatestUpdatesSystems />
                </div>
                <div className="col-xl-4">
                    <LatestUpdatesBrowsers />
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <EventDetails />
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        total_counts: state.dashboard.total_counts,
        total: state.dashboard.total,
        terminated: state.dashboard.terminated,
        events: state.dashboard.events
    };
};

const mapDispatchToProps = dispatch => ({
    getStatConferencesCounts: () => dispatch(dashboardStore.actions.getStatConferencesCounts()),
    getStatEvents: () => dispatch(dashboardStore.actions.getStatEvents())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
