import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import Chat from "../../../../../components/Chat1";
import ToolBar from "../../../../../components/Toolbar";
import SelectDeviceDialog from "../../../../../components/SelectDeviceDialog";
import SelectBgDialog from "../../../../../components/SelectBgDialog";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import { meetingVideo as useStyles } from "../../../../../components/CommonStyles";

import * as eventStore from "../../../../../store/ducks/event.duck";
import * as messageStore from "../../../../../store/ducks/messages.duck";
import { UserRole } from "../../../../../components/utils/UserRole";
import MeetContainer from "../../../../../components/MeetContainer";
import { calcSidePlaceHeight } from "../../../../../components/utils/VideoLayoutUtils";
import useHandleMosaicView from "../../../../../components/useHandleMosaicView";
import useHandleMute from "../../../../../components/useHandleMute";
import VideoTrack from "../../../../../components/VideoTrack";
import RemoteVideo from "../../../../../components/RemoteVideo";
import { EventType } from "../../../../../components/utils/EventType";

import { jitsiMeeting } from "../../../../../components/JitsiMeeting";
import AudioPlace from "../../../../../components/AudioPlace";

function MeetingVideo(props) {
    const {
        event,
        isEndMeeting,
        roomname,
        isMuted,
        isChatBoxOpen,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        openVideoSettingDlg,
        setOpenVideoSettingDlg,
        openAudioInputSettingDlg,
        setOpenAudioInputSettingDlg,
        openBgSettingDlg,
        setOpenBgSettingDlg,
        isRaise,
        openChatBox,
        isMosaic,
        // isNotify,
        user,
        authToken,
        addStat,
        startStatId,
        cameraSetting,
        audioOutputSetting,
        audioInputSetting,
        disableMic,
        disableVideo,
        focusedId,
        cameraDevices,
        audioOutputDevices,
        audioInputDevices,
        participants,
        setParticipants,
        messageList,
        getMessages,
        setToParticipant,
        audioLevels,
        localTracks,
        isOriginal,
        audioTracks,
        isSubTitle,
        intl
    } = props;

    const classes = useStyles(props);

    useEffect(() => {
        if (isEndMeeting) {
            unload();
        } else {
            startJitSiMeeting();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEndMeeting]);

    useEffect(() => {
        getMessages();

        calcSidePlaceHeight();
        return () => {
            if (jitsiMeeting.connection) {
                unload();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (jitsiMeeting.room) {
            postStat(3, roomname);
            jitsiMeeting.changeLangTrack(roomname);
            jitsiMeeting.setRoomFeature();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomname]);

    useHandleMute(isMuted);

    useHandleMosaicView(isMosaic, props.screenMode, participants);

    useEffect(() => {
        jitsiMeeting.handleRaiseHand(isRaise);
    }, [isRaise]);

    useEffect(() => {
        jitsiMeeting.setIsWithFloor(isOriginal);
    }, [isOriginal]);

    async function startJitSiMeeting() {
        if (!event) {
            return;
        }

        const appId = process.env.REACT_APP_APP_ID;
        jitsiMeeting.connect(appId, authToken, event, roomname, {
            isMicOn: true
        });

        postStat(0, roomname);
    }

    function renderRemoteParticipant(p) {
        const pId = p.getId();
        const displayName = p.getDisplayName();

        const videoTrack = jitsiMeeting.remoteTracks[pId].find(
            t => t.getType() === "video" && !t.disposed
        );

        let videoElement = "";
        let placeClass = classes.participantContainer;

        // Focus Element
        if (
            !(event.event_type === EventType.CONFERENCE || event.event_type === EventType.WEB) &&
            pId === focusedId
        ) {
            placeClass += ` ${classes.participantContainerFocused}`;
        }

        let _isMuted = true;
        _isMuted = !!jitsiMeeting.remoteTracks[pId].find(t => t.getType() === "audio" && t.muted);

        if (jitsiMeeting.remoteTracks[pId]) {
            if (parseInt(p.getProperty("role")) === UserRole.INTERPRETER) {
                placeClass += " interpreterPlace";
            }

            if (parseInt(p.getProperty("role")) !== UserRole.OBSERVER) {
                if (videoTrack) {
                    videoElement = (
                        <VideoTrack
                            id={`${pId}video`}
                            className={classes.video}
                            muted={true}
                            videoTrack={{ jitsiTrack: videoTrack }}
                        />
                    );

                    jitsiMeeting.setReceiverConstraints(pId);
                }
            }
        }

        // Participant's output language
        const p_output = p.getProperty("output");
        const is_non = p_output ? p_output.includes("non-") : false;

        let output_lang = null;
        if (is_non) {
            output_lang = { lang_name: "ORI" };
        } else {
            const p_output_room = event.event_rooms.find(
                event_room => event_room.room.room_name === p_output
            );

            output_lang = p_output_room ? p_output_room.room.output_lang : null;
        }

        const isHand = !!p.getProperty("hand");

        return (
            <div
                key={pId}
                id={`${pId}Place`}
                className={placeClass}
                aria-hidden="true"
                onClick={() => handleClickSidePlace(pId)}
            >
                <RemoteVideo
                    id={pId}
                    displayName={displayName}
                    baseClasses={classes}
                    openChatBox={openChatBox}
                    setToParticipant={setToParticipant}
                    messageList={messageList}
                    lang={intl.locale}
                    isNotify={false}
                    isMicOn={!is_non}
                    isMuted={_isMuted}
                    audioLevels={audioLevels}
                    isMosaic={isMosaic}
                    output_lang={output_lang}
                    isHand={isHand}
                >
                    {videoElement}
                </RemoteVideo>
            </div>
        );
    }

    function unload() {
        postStat(2, roomname);

        jitsiMeeting.unload();
    }

    function postStat(status, _roomname) {
        const currentRoom = event.event_rooms.filter(
            event_room => event_room.room.room_name === _roomname
        );
        let stat = {
            event_id: event.id,
            status: status,
            room_id: currentRoom.length > 0 ? currentRoom[0].room_id : 0,
            start_stat_id: startStatId ? startStatId : 0
        };
        addStat(stat);
    }

    function handleClickSidePlace(pId) {
        if (!jitsiMeeting.room) return;

        if (pId === "selfPlace") {
            jitsiMeeting.setFocusedId(jitsiMeeting.room.myUserId());
        } else {
            jitsiMeeting.setReceiverConstraints(pId);
            jitsiMeeting.setFocusedId(pId);
        }
        setParticipants(jitsiMeeting.room.getParticipants());
    }

    function _getBigVideoTrack() {
        let newBigVideoTrack = localTracks.find(t => t.getType() === "video");
        if (!jitsiMeeting.room || !focusedId) return newBigVideoTrack;

        if (focusedId === jitsiMeeting.room.myUserId()) {
            newBigVideoTrack = localTracks.find(t => t.getType() === "video");
        } else {
            const participant = jitsiMeeting.room.getParticipantById(focusedId);
            if (!participant) return null;

            if (participant.getProperty("role") !== UserRole.OBSERVER) {
                console.log(
                    "🚀 ~ _getBigVideoTrack ~ participant.getProperty(role):",
                    participant.getProperty("role")
                );
            }

            if (participant.getProperty("role") === UserRole.INTERPRETER) {
                console.log(
                    "🚀 ~ _getBigVideoTrack ~ participant.getProperty(role):",
                    participant.getProperty("role")
                );
            }

            const tracks = participant.getTracks();
            if (tracks.find(t => t.getType() === "video")) {
                newBigVideoTrack = tracks.find(t => t.getType() === "video");
            } else {
                newBigVideoTrack = null;
            }
        }

        return newBigVideoTrack;
    }

    // TODO:
    function handleHoverEvent(event) {
        // console.log("🚀 ~ MeetingVideo ~ event:", event);
    }

    // TODO:
    function handleLeaveEvent(event) {
        // console.log("🚀 ~ MeetingVideo ~ event:", event);
    }

    /**
     * Handle CameraSetting Change
     * @param {String} newCameraInput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleVideoSettingChange(newCameraInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setCameraSetting(newCameraInput);
        }
        setOpenVideoSettingDlg(isOpen);
    }

    /**
     * Handle AudioOutputSetting Change
     * @param {String} newAudioOutput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioOutputSettingChange(newAudioOutput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioOutputSetting(newAudioOutput);
        }
        setOpenAudioOutputSettingDlg(isOpen);
    }

    /**
     * Handle AudioInputSetting Change
     * @param {String} newAudioInput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    async function handleAudioInputSettingChange(newAudioInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioInputSetting(newAudioInput);
        }
        setOpenAudioInputSettingDlg(isOpen);
    }

    function sendTextMessage(id, message) {
        if (id === "all") {
            jitsiMeeting.room.sendTextMessage(message);
        } else {
            jitsiMeeting.room.sendPrivateTextMessage(id, message);
        }
    }

    /**
     * Handle CameraSetting Change
     * @param {Object} background
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleBgSettingChange(background, isOpen = true) {
        // if (isOpen) {
        jitsiMeeting.setBackgroundSetting(background);
        // }
        setOpenBgSettingDlg(isOpen);
    }

    function validUsers(p) {
        const role = parseInt(p.getProperty("role"));
        return role > 0 && role <= UserRole.USER && role !== UserRole.INTERPRETER;
    }

    return (
        <>
            <MeetContainer className="row">
                <Grid item xs={12} style={{ height: "100%" }}>
                    <div
                        id="localPlace"
                        className={classes.localPlace}
                        onMouseEnter={handleHoverEvent}
                        onMouseLeave={handleLeaveEvent}
                    >
                        <div className={classes.avatar}>
                            <img
                                src={
                                    event.pic
                                        ? process.env.REACT_APP_FILE_URL + event.pic
                                        : toAbsoluteUrl("/media/logos/logo-trans.png")
                                }
                                alt="avatar"
                            />
                        </div>

                        <div className={classes.chatContainer}>
                            <Chat
                                open={isChatBoxOpen}
                                setIsChatDlg={openChatBox}
                                sendTextMessage={sendTextMessage}
                                participants={participants}
                            />
                        </div>

                        <ToolBar
                            disableMic={disableMic}
                            disableVideo={disableVideo}
                            disableShare={disableVideo}
                        />

                        <VideoTrack
                            id="bigVideo"
                            className={`${classes.bigVideo}`}
                            muted={true}
                            videoTrack={{ jitsiTrack: _getBigVideoTrack() }}
                        />
                        <div className={isMosaic ? classes.mosaicOverlay : classes.hideSelfVideo} />
                        <div
                            id="sidePlace"
                            className={isMosaic ? classes.mosaicRemoteContainer : classes.sidePlace}
                        >
                            <div className="sideWrapper">
                                <div id="remotePlace" className={classes.remotePlace}>
                                    <div
                                        id="selfPlace"
                                        className={`${classes.participantContainer} ${
                                            jitsiMeeting.room &&
                                            focusedId === jitsiMeeting.room.myUserId()
                                                ? classes.participantContainerFocused
                                                : ""
                                        }`}
                                        aria-hidden="true"
                                        onClick={() => handleClickSidePlace("selfPlace")}
                                    >
                                        <div className={classes.videoContainer}>
                                            <VideoTrack
                                                id="selfVideo"
                                                className={classes.video}
                                                muted={true}
                                                videoTrack={{
                                                    jitsiTrack: localTracks.find(
                                                        track => track.getType() === "video"
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className="displayName">
                                            {user.name}(
                                            {intl.formatMessage({
                                                id: "CHAT.ME"
                                            })}
                                            )
                                        </div>
                                        {isRaise && (
                                            <div className={classes.hand}>
                                                <i className="fas fa-hand-paper"></i>
                                            </div>
                                        )}
                                    </div>
                                    {participants
                                        .filter(p => validUsers(p))
                                        .map(p => renderRemoteParticipant(p))}
                                </div>
                            </div>
                        </div>

                        {Object.keys(audioTracks).map(key => (
                            <AudioPlace
                                id={`${key}audio`}
                                key={key}
                                audioTrack={{
                                    jitsiTrack: audioTracks[key].audioTrack
                                }}
                                participantId={key}
                                _muted={!!audioTracks[key].muted}
                                _volume={audioTracks[key].volume ? audioTracks[key].volume : 0}
                            />
                        ))}

                        {isSubTitle && (
                            <div id="subTitleWrapper" className={classes.subTitleWrapper}>
                                <div id="subTitle" className={classes.subTitle}>
                                    {event.subtitle && event.subtitle !== "" && (
                                        <iframe
                                            title={event.title}
                                            src={event.subtitle}
                                            className={classes.subTitleIframe}
                                        ></iframe>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Camera Setting Dialog */}
                        <SelectDeviceDialog
                            id="cameraSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA"
                            })}
                            isOpen={openVideoSettingDlg}
                            devices={cameraDevices}
                            currentValue={cameraSetting}
                            onChange={handleVideoSettingChange}
                        />

                        {/* Audio Output Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioOutputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS"
                            })}
                            isOpen={openAudioOutputSettingDlg}
                            devices={audioOutputDevices}
                            currentValue={audioOutputSetting}
                            onChange={handleAudioOutputSettingChange}
                        />

                        {/* Audio Input Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioInputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS_TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS"
                            })}
                            isOpen={openAudioInputSettingDlg}
                            devices={audioInputDevices}
                            currentValue={audioInputSetting}
                            onChange={handleAudioInputSettingChange}
                        />

                        {/* Virtual Background Setting Dialog */}
                        <SelectBgDialog
                            id="bgSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.VIRTUAL_BACKGROUND.TITLE"
                            })}
                            isOpen={openBgSettingDlg}
                            eventBg={event.bg_ic}
                            cameraSetting={cameraSetting}
                            currentValue={event.bg_pic}
                            onChange={handleBgSettingChange}
                        />
                    </div>
                </Grid>
            </MeetContainer>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        authToken: state.auth.authToken,
        isEndMeeting: state.event.isEndMeeting,
        startStatId: state.event.startStatId,
        room: state.event.room,
        isJoining: state.event.isJoining,
        isMuted: state.event.isMuted,
        isVideoOn: state.event.isVideoOn,
        cameraSetting: state.event.cameraSetting,
        audioOutputSetting: state.event.audioOutputSetting,
        audioInputSetting: state.event.audioInputSetting,
        cameraDevices: state.event.cameraDevices,
        audioOutputDevices: state.event.audioOutputDevices,
        audioInputDevices: state.event.audioInputDevices,
        isChatBoxOpen: state.event.isChatBoxOpen,
        openVideoSettingDlg: state.event.openVideoSettingDlg,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg,
        openAudioInputSettingDlg: state.event.openAudioInputSettingDlg,
        openBgSettingDlg: state.event.openBgSettingDlg,
        isRaise: state.event.isRaise,
        isMosaic: state.event.isMosaic,
        screenMode: state.event.screenMode,
        isCamera: state.event.isCamera,
        isShareOtherCamera: state.event.isShareOtherCamera,
        disableMic: state.event.disableMic,
        disableVideo: state.event.disableVideo,
        participants: state.event.participants,
        toParticipant: state.event.toParticipant,
        messageList: state.messages.messageList,
        isSidePlaceShow: state.event.isSidePlaceShow,
        audioLevels: state.event.audioLevels,
        localTracks: state.event.localTracks,
        focusedId: state.event.focusedId,
        audioTracks: state.event.audioTracks,
        isSubTitle: state.event.isSubTitle
    };
};

const mapDispatchToProps = dispatch => ({
    addStat: data => dispatch(eventStore.actions.addStat(data)),
    openChatBox: isChatBoxOpen => dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
    setOpenAudioInputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
    setOpenVideoSettingDlg: isOpen => dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
    setOpenBgSettingDlg: isOpen => dispatch(eventStore.actions.setOpenBgSettingDlg(isOpen)),
    setParticipants: participants => dispatch(eventStore.actions.setParticipants(participants)),
    setToParticipant: toParticipant => dispatch(eventStore.actions.setToParticipant(toParticipant)),
    getMessages: () => dispatch(messageStore.actions.getMessages())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MeetingVideo));
