import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";

import * as channelsStore from "../../../../store/ducks/channels.duck";

function ChannelDlg(props) {
    const { onClose, open, channelId, channelValue, dispatch, intl } = props;

    const [channel, setChannel] = useState({
        channel_name: "",
        channel_detail: ""
    });

    useEffect(() => {
        if (channelId && channelId !== -1 && channelValue) {
            setChannel({
                channel_name: channelValue.channel_name,
                channel_detail: channelValue.channel_detail
            });
        }
    }, [channelId, channelValue]);

    const handleClose = () => {
        setChannel({ channel_name: "", channel_detail: "" });
        onClose();
    };

    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });
    // Submit Button Effects
    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };
    // Handle Change Channel Values
    const handleChannelNameChange = e => {
        const newValue = e.target.value;
        setChannel(prevChannel => {
            return { ...prevChannel, channel_name: newValue };
        });
    };

    const handleChannelDetailChange = e => {
        const newValue = e.target.value;
        setChannel(prevChannel => {
            return { ...prevChannel, channel_detail: newValue };
        });
    };

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {intl.formatMessage({ id: "CHANNEL.FIELD.DETAIL" })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{intl.formatMessage({ id: "EVENTDLG.DESC" })}</DialogContentText>
                <Formik
                    initialValues={channel}
                    enableReinitialize
                    validate={values => {
                        const errors = {};

                        if (!values.channel_name) {
                            errors.channel_name = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.channel_detail) {
                            errors.channel_detail = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        enableLoading();
                        setTimeout(() => {
                            if (channelId !== -1) {
                                dispatch(
                                    channelsStore.actions.updateChannels(
                                        Object.assign(channelValue, channel)
                                    )
                                );
                                disableLoading();
                                onClose();
                            } else {
                                dispatch(channelsStore.actions.addChannel(channel));
                                disableLoading();
                                onClose();
                            }
                        }, 1000);
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form"
                            onSubmit={handleSubmit}
                        >
                            {status ? (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            ) : (
                                <></>
                            )}

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "CHANNEL.FIELD.NAME"
                                    })}
                                    className="kt-width-full"
                                    name="channel_name"
                                    onBlur={handleBlur}
                                    onChange={handleChannelNameChange}
                                    value={values.channel_name}
                                    helperText={touched.channel_name && errors.channel_name}
                                    error={Boolean(touched.channel_name && errors.channel_name)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "CHANNEL.FIELD.DETAIL"
                                    })}
                                    className="kt-width-full"
                                    name="channel_detail"
                                    onBlur={handleBlur}
                                    onChange={handleChannelDetailChange}
                                    value={values.channel_detail}
                                    helperText={touched.channel_detail && errors.channel_detail}
                                    error={Boolean(touched.channel_detail && errors.channel_detail)}
                                />
                            </div>

                            <div className="kt-login__actions">
                                <DialogActions>
                                    <Button color="primary" onClick={handleClose}>
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.CANCEL"
                                        })}
                                    </Button>
                                    <button
                                        id="kt_login_signin_submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                            {
                                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                            }
                                        )}`}
                                        style={loadingButtonStyle}
                                    >
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.SUBMIT"
                                        })}
                                    </button>
                                </DialogActions>
                            </div>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

ChannelDlg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    channelId: PropTypes.any
};

export default injectIntl(connect()(ChannelDlg));
