import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles, colors, IconButton, Fab } from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import PanToolIcon from "@material-ui/icons/PanTool";
import CallEndIcon from "@material-ui/icons/CallEnd";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import SpeakerIcon from "@material-ui/icons/Speaker";
import MessageIcon from "@material-ui/icons/Message";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import ImageIcon from "@material-ui/icons/Image";
import { Dropdown } from "react-bootstrap";
import CustomMenu from "./CustomMenu";
import CustomToggle from "./CustomToggle";
import * as eventStore from "../store/ducks/event.duck";
import { UserRole } from "./utils/UserRole";
import { jitsiMeeting } from "./JitsiMeeting";

const useStyles = makeStyles(theme => ({
    toolbox: {
        position: "absolute",
        bottom: props => (props.isToolbarShow ? 0 : -56),
        left: "auto",
        right: "auto",
        zIndex: "500",
        width: "100%",
        // backgroundImage: "linear-gradient(to top,rgba(0,0,0,.6),rgba(0,0,0,0))",
        backgroundColor: "#525050",
        display: "flex",
        justifyContent: "center",
        transition: "bottom .3s ease-in"
    },
    toolboxLeft: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        alignSelf: "center",
        width: "40%"
    },
    toolboxCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        width: "20%"
    },
    toolboxRight: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        alignSelf: "center",
        width: "40%"
    },
    shareScreen: {
        margin: 6,
        color: colors.common.white,
        minWidth: "40px !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        }
    },
    call: {
        margin: theme.spacing(1),
        backgroundColor: colors.red[800],
        color: colors.common.white,
        minWidth: "40px !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        },
        "&:hover": {
            color: colors.common.white,
            backgroundColor: colors.red[300]
        }
    },
    "@keyframes blinker": {
        from: { backgroundColor: colors.yellow["A400"] },
        to: { backgroundColor: "#0abb87" }
    },
    chat: {
        animationName: "$blinker",
        animationDuration: "1s",
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",
        "animation-fill-mode": "initial",
        "&:hover": {
            backgroundColor: colors.yellow[800]
        }
    },
    videoMute: {
        height: "40px !important",
        minWidth: "40px !important",
        borderRadius: "50% !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        }
    },
    mosaicButton: {
        padding: 10
    },
    volume: {
        color: colors.common.white,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 8,
            paddingRight: 8
        }
    },
    toolbarItem: {
        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },
    settingItem: {
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    }
}));

function Toolbar(props) {
    const {
        isMicOn,
        isMuted,
        isVideoOn,
        isChatBoxOpen,
        isNewMsg,
        isCamera,
        isRaise,
        isMosaic,
        cameraDevices,
        isShareOtherCamera,
        isInterpreter,
        disableMic,
        disableVideo,
        isSubTitle,
        intl
    } = props;
    console.log("🚀 ~ Toolbar ~ disableMic:", disableMic);
    const classes = useStyles(props);

    function handleMute(value) {
        if (isMicOn) jitsiMeeting.changeMute(value);
    }

    function handleVideoOn() {
        if (!disableVideo) jitsiMeeting.changeVideoOn(!isVideoOn);
    }

    function handleChat() {
        props.openChatBox(!isChatBoxOpen);
    }

    function handleLeave() {
        props.endMeeting();
    }

    function shareOtherCamera() {
        jitsiMeeting.changeShareOtherCamera(!isShareOtherCamera);
    }

    function switchVideo(e) {
        jitsiMeeting.setIsCamera(!isCamera);
    }

    function handleMosaicView() {
        props.updateIsMosaic(!isMosaic);
    }

    function handleSetSize(mode) {
        props.updateScreenMode(mode);
    }

    function handleVideoSetting() {
        props.setOpenVideoSettingDlg(true);
    }

    function handleAudioOutputSetting() {
        props.setOpenAudioOutputSettingDlg(true);
    }

    function handleAudioInputSetting(params) {
        props.setOpenAudioInputSettingDlg(true);
    }

    function handleBgSetting() {
        props.setOpenBgSettingDlg(true);
    }

    function handleRaiseHand() {
        props.setIsRaise(!isRaise);
    }

    function handleSubtitleOn(value) {
        jitsiMeeting.setIsSubTitle(value);
    }

    return (
        <div id="toolbox" className={classes.toolbox}>
            <div id="toolboxLeft" className={classes.toolboxLeft}>
                {!isInterpreter &&
                    !props.disableAudioInput &&
                    (!isMuted ? (
                        <Fab
                            color="secondary"
                            size="small"
                            aria-label="MicOn"
                            className={classes.shareScreen}
                            onClick={() => handleMute(true)}
                            disabled={disableMic}
                        >
                            <MicIcon />
                        </Fab>
                    ) : (
                        <Fab
                            color="inherit"
                            size="small"
                            aria-label="MicOff"
                            className={classes.call}
                            onClick={() => handleMute(false)}
                            disabled={disableMic}
                        >
                            <MicOffIcon />
                        </Fab>
                    ))}
                {!props.disableVideoInput &&
                    (isVideoOn ? (
                        <Fab
                            color="secondary"
                            size="small"
                            aria-label="Video On"
                            onClick={handleVideoOn}
                            className={`${classes.videoMute} ${classes.shareScreen}`}
                            disabled={disableVideo}
                        >
                            <VideocamIcon />
                        </Fab>
                    ) : (
                        <Fab
                            color="inherit"
                            size="small"
                            aria-label="Video Off"
                            onClick={handleVideoOn}
                            className={`${classes.call} ${classes.videoMute} ${classes.shareScreen}`}
                            disabled={disableVideo}
                        >
                            <VideocamOffIcon />
                        </Fab>
                    ))}
                {!props.isEmitter && !props.disableRaiseHand && (
                    <IconButton
                        color="primary"
                        aria-label="Screen Size"
                        className={`${classes.shareScreen} ${classes.toolbarItem}`}
                        onClick={handleRaiseHand}
                    >
                        <PanToolIcon />
                    </IconButton>
                )}

                {!props.isEmitter && (
                    <Fab
                        color="secondary"
                        size="small"
                        aria-label="Subtitle On/off"
                        onClick={() => handleSubtitleOn(!isSubTitle)}
                        className={`${!isSubTitle ? classes.call : ""} ${classes.videoMute} ${
                            classes.shareScreen
                        }`}
                        disabled={disableVideo}
                    >
                        <SubtitlesIcon />
                    </Fab>
                )}
            </div>
            <div id="toolboxCenter" className={classes.toolboxCenter}>
                {!props.isEmitter && !props.disableChat && (
                    <Fab
                        color="secondary"
                        size="small"
                        aria-label="Chat"
                        className={`${classes.shareScreen} ${isNewMsg ? classes.chat : ""}`}
                        onClick={handleChat}
                    >
                        <MessageIcon />
                    </Fab>
                )}
                <Fab
                    color="inherit"
                    size="small"
                    aria-label="CallEnd"
                    className={classes.call}
                    onClick={handleLeave}
                >
                    <CallEndIcon />
                </Fab>
                {!props.disableShare && (
                    <Dropdown>
                        <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-basic"
                            drop={"up"}
                            navbar={false}
                        >
                            <Fab
                                color="secondary"
                                size="small"
                                aria-label="ShareScreen"
                                className={classes.shareScreen}
                            >
                                <ScreenShareIcon />
                            </Fab>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenu}>
                            {cameraDevices && cameraDevices.length > 1 ? (
                                <Dropdown.Item
                                    eventKey="1"
                                    onClick={shareOtherCamera}
                                    active={isShareOtherCamera}
                                >
                                    <VideocamIcon />{" "}
                                    {intl.formatMessage({
                                        id: "VIDEO.SHARE.CAMERA"
                                    })}
                                </Dropdown.Item>
                            ) : (
                                ""
                            )}

                            <Dropdown.Item eventKey="2" onClick={switchVideo} active={!isCamera}>
                                <DesktopWindowsIcon />{" "}
                                {intl.formatMessage({
                                    id: "VIDEO.SHARE.DESKTOP"
                                })}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
            <div id="toolbox-right" className={classes.toolboxRight}>
                {!props.isEmitter &&
                    props.user.role !== UserRole.LITE_USER &&
                    props.user.role !== UserRole.WEB_USER && (
                        <IconButton
                            color="primary"
                            aria-label="Layout"
                            className={`${classes.volume} ${classes.mosaicButton} ${classes.toolbarItem}`}
                            onClick={handleMosaicView}
                        >
                            <ViewModuleIcon />
                        </IconButton>
                    )}
                <Dropdown>
                    <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-basic"
                        drop={"up"}
                        navbar={false}
                    >
                        <IconButton
                            color="primary"
                            aria-label="Screen Size"
                            className={classes.volume}
                        >
                            <AspectRatioIcon />
                        </IconButton>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        <Dropdown.Item eventKey="1" onClick={() => handleSetSize(0)}>
                            <RemoveFromQueueIcon />{" "}
                            {intl.formatMessage({ id: "VIDEO.SCREEN.NORMAL" })}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={() => handleSetSize(1)}>
                            <FullscreenExitIcon />{" "}
                            {intl.formatMessage({ id: "VIDEO.SCREEN.LARGE" })}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={() => handleSetSize(2)}>
                            <FullscreenIcon /> {intl.formatMessage({ id: "VIDEO.SCREEN.FULL" })}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                    <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-basic"
                        drop={"up"}
                        navbar={false}
                    >
                        <IconButton color="primary" aria-label="More" className={classes.volume}>
                            <MoreVertIcon />
                        </IconButton>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        {!props.disableVideoInput && (
                            <Dropdown.Item eventKey="1" onClick={handleVideoSetting}>
                                <VideocamIcon />{" "}
                                {intl.formatMessage({
                                    id: "VIDEO.VIDEO_INPUT"
                                })}
                            </Dropdown.Item>
                        )}
                        {!props.isEmitter && !props.disableVideoInput && (
                            <Dropdown.Item eventKey="1" onClick={handleBgSetting}>
                                <ImageIcon />{" "}
                                {intl.formatMessage({
                                    id: "VIDEO.VIRTUAL_BACKGROUNDS"
                                })}
                            </Dropdown.Item>
                        )}
                        {!props.disableAudioInput && (
                            <Dropdown.Item eventKey="3" onClick={handleAudioInputSetting}>
                                <SettingsVoiceIcon />{" "}
                                {intl.formatMessage({
                                    id: "VIDEO.AUDIO_INPUT"
                                })}
                            </Dropdown.Item>
                        )}
                        {!props.disableAudioOutput && (
                            <Dropdown.Item eventKey="2" onClick={handleAudioOutputSetting}>
                                <SpeakerIcon />{" "}
                                {intl.formatMessage({
                                    id: "VIDEO.AUDIO_OUTPUT"
                                })}
                            </Dropdown.Item>
                        )}
                        {!props.isEmitter && props.user.role !== UserRole.LITE_USER && (
                            <Dropdown.Item
                                eventKey="5"
                                onClick={handleMosaicView}
                                className={classes.settingItem}
                            >
                                <ViewModuleIcon />{" "}
                                {intl.formatMessage({
                                    id: "TOOLBOX.SETTING.LAYOUT"
                                })}
                            </Dropdown.Item>
                        )}
                        {!props.isEmitter && !props.disableRaiseHand && (
                            <Dropdown.Item
                                eventKey="6"
                                onClick={handleRaiseHand}
                                className={classes.settingItem}
                            >
                                <PanToolIcon />{" "}
                                {intl.formatMessage({
                                    id: "TOOLBOX.SETTING.RAISE_HAND"
                                })}
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const props = {
        user: state.auth.user,
        isMicOn: state.event.isMicOn,
        isMuted: state.event.isMuted,
        isVideoOn: state.event.isVideoOn,
        cameraDevices: state.event.cameraDevices,
        isChatBoxOpen: state.event.isChatBoxOpen,
        isNewMsg: state.event.isNewMsg,
        isRaise: state.event.isRaise,
        isMosaic: state.event.isMosaic,
        isCamera: state.event.isCamera,
        isShareOtherCamera: state.event.isShareOtherCamera,
        isToolbarShow: state.event.isToolbarShow,
        isSubTitle: state.event.isSubTitle
    };

    if (state.auth.user.role === UserRole.EMITTER) {
        const emitterProps = {
            isEmitter: true
        };
        return {
            ...props,
            ...emitterProps
        };
    }

    return props;
};

const mapDispatchToProps = dispatch => {
    const actions = {
        endMeeting: () => dispatch(eventStore.actions.endMeeting()),
        changeMute: isMuted => dispatch(eventStore.actions.changeMute(isMuted)),
        changeVideoOn: isVideoOn => dispatch(eventStore.actions.changeVideoOn(isVideoOn)),
        openChatBox: isChatBoxOpen => dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
        setOpenAudioInputSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
        setOpenAudioOutputSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
        setOpenVideoSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
        setOpenBgSettingDlg: isOpen => dispatch(eventStore.actions.setOpenBgSettingDlg(isOpen)),
        setIsRaise: isRaise => dispatch(eventStore.actions.setIsRaise(isRaise)),
        updateScreenMode: mode => dispatch(eventStore.actions.updateScreenMode(mode)),
        updateIsMosaic: isMosaic => dispatch(eventStore.actions.updateIsMosaic(isMosaic)),
        setIsCamera: isCamera => dispatch(eventStore.actions.setIsCamera(isCamera)),
        setIsShareOtherCamera: isShareOtherCamera =>
            dispatch(eventStore.actions.setIsShareOtherCamera(isShareOtherCamera))
    };
    return actions;
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Toolbar));
