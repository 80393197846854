import React from "react";
import { Route, Switch } from "react-router-dom";
import EventList from "./EventList";
import Event from "./Event";

export default function Events() {
    return (
        <Switch>
            <Route exact={true} path="/events" component={EventList} />
            <Route exact={true} path="/events/:id" component={Event} />
        </Switch>
    );
}
