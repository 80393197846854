import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

function AuthPage(props) {
    const title = `Welcome to ${process.env.REACT_APP_TITLE}!`;
    const sub_title = process.env.REACT_APP_SUB_TITLE;
    const [isNotfy, setNotify] = React.useState(false);

    useEffect(() => {
        if (props.notification) setNotify(true);
    }, [props.notification]);

    function handleClose(e) {
        setNotify(false);
    }
    return (
        <>
            <div className="kt-grid kt-grid--ver kt-grid--root">
                <div
                    id="kt_login"
                    className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
                >
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div
                            className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                            style={{
                                backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
                            }}
                        >
                            <div className="kt-grid__item">
                                <Link to="/" className="kt-login__logo">
                                    <img
                                        alt="Logo"
                                        src={toAbsoluteUrl("/media/logos/logo-lg.png")}
                                    />
                                </Link>
                            </div>
                            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                                <div className="kt-grid__item kt-grid__item--middle">
                                    <h3 className="kt-login__title">{title}</h3>
                                    <h4 className="kt-login__subtitle">{sub_title}</h4>
                                </div>
                            </div>
                            <div className="kt-grid__item">
                                <div className="kt-login__info">
                                    <div className="kt-login__copyright">
                                        &copy; 2020 {process.env.REACT_APP_TITLE}
                                    </div>
                                    <div className="kt-login__menu">
                                        <Link to="/terms" className="kt-link">
                                            Privacy
                                        </Link>
                                        <Link to="/terms" className="kt-link">
                                            Legal
                                        </Link>
                                        <Link to="/terms" className="kt-link">
                                            Contact
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Snackbar
                            open={isNotfy}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center"
                            }}
                            autoHideDuration={6000}
                            onClose={handleClose}
                        >
                            <MuiAlert
                                elevation={6}
                                variant="filled"
                                severity={"success"}
                                onClose={handleClose}
                            >
                                {props.notification}
                            </MuiAlert>
                        </Snackbar>

                        <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                            <Switch>
                                <Route path="/auth/login" component={Login} />
                                {process.env.REACT_APP_SECURITY === "true" && (
                                    <Route path="/auth/registration" component={Registration} />
                                )}

                                <Route path="/auth/resetPassword" component={ResetPassword} />
                                {process.env.REACT_APP_SECURITY === "true" && (
                                    <Route
                                        path="/auth/forgot-password"
                                        component={ForgotPassword}
                                    />
                                )}
                                <Redirect from="/auth" exact={true} to="/auth/login" />
                                <Redirect to="/auth/login" />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        notification: state.auth.notification
    };
};

export default connect(mapStateToProps, null)(AuthPage);
