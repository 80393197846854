import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";
import * as events from "./ducks/events.duck";
import * as channels from "./ducks/channels.duck";
import * as admins from "./ducks/admins.duck";
import * as managers from "./ducks/managers.duck";
import * as emitters from "./ducks/emitters.duck";
import * as customers from "./ducks/customers.duck";
import * as trans from "./ducks/trans.duck";
import * as languages from "./ducks/languages.duck";
import * as messages from "./ducks/messages.duck";
import * as dashboard from "./ducks/dashboard.duck";
import * as event from "./ducks/event.duck";

import * as adminsModule from "./ducks/admins/index.duck";
import * as emittersModule from "./ducks/emitters/index.duck";
import * as interpretersModule from "./ducks/interpreters/index.duck";
import * as customersModule from "./ducks/customers/index.duck";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    i18n: metronic.i18n.reducer,
    builder: metronic.builder.reducer,
    events: events.reducer,
    channels: channels.reducer,
    admins: admins.reducer,
    emitters: emitters.reducer,
    managers: managers.reducer,
    customers: customers.reducer,
    trans: trans.reducer,
    languages: languages.reducer,
    messages: messages.reducer,
    dashboard: dashboard.reducer,
    event: event.reducer,

    adminReducer: adminsModule.reducer,
    emitterReducer: emittersModule.reducer,
    interpreterReducer: interpretersModule.reducer,
    customerReducer: customersModule.reducer
});

export function* rootSaga() {
    yield all([
        auth.saga(),
        events.eventsSaga(),
        channels.channelsSaga(),
        admins.adminsSaga(),
        managers.managersSaga(),
        emitters.emittersSaga(),
        customers.customersSaga(),
        trans.transSaga(),
        languages.languagesSaga(),
        messages.messagesSaga(),
        dashboard.dashboardSaga(),

        event.eventSaga(),
        adminsModule.adminsModuleSaga(),
        emittersModule.emittersModuleSaga(),
        interpretersModule.interpretersModuleSaga(),
        customersModule.customersModuleSaga()
    ]);
}
