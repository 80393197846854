import { makeStyles, colors } from "@material-ui/core";
const { common } = colors;

export const meeting = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    eventPlace: {
        // marginTop: props => (props.isStartMeeting ? 0 : 20),
        marginTop: 20,
        marginLeft: "auto",
        marginRight: "auto"
        // width: '100%'
    },
    videoPlace: {
        flex: "100%"
        // maxWidth: "calc(100% - 380px)",
        // [theme.breakpoints.down("sm")]: {
        //     flex: "initial",
        //     maxWidth: "initial",
        // }
    },
    control: {
        // width: 340,
        // maxWidth: 340,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            maxWidth: "100%"
        }
        // marginLeft: "auto",
        // marginTop: 5
    },
    fullWidthControl: {
        position: "absolute",
        right: 20
    },
    paper: {
        width: "100%"
    },
    table: {
        // minWidth: 350
    },
    tableWrapper: {
        overflowX: "auto"
    },
    buttonRed: {
        backgroundColor: colors.red[800],
        "&:hover": {
            color: colors.common.white,
            backgroundColor: colors.red[300]
        }
    },
    button: {
        margin: theme.spacing(1)
    },
    flag: {
        width: 32,
        height: 24,
        marginRight: theme.spacing(1),
        borderRadius: 4
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    formControl: {
        width: "100%"
    },
    langControl: {
        marginBottom: theme.spacing(1)
    },
    volumeControl: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        marginBottom: theme.spacing(1)
    },
    volume: {
        margin: theme.spacing(1)
    },
    channelUsersContainer: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1)
    },
    headerContent: {
        margin: 0,
        textAlign: "center"
    },
    headerShadowNone: {
        boxShadow: "none !important"
    },
    title: {
        // display: "inline-block"
    },
    description: {
        margin: theme.spacing(1)
    },
    avatar: {
        maxWidth: "100%",
        maxHeight: 250,
        height: "auto"
    }
}));

export const meetingVideo = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 350
    },
    tableWrapper: {
        overflowX: "auto"
    },
    avatar: {
        position: "absolute",
        top: "5%",
        left: "5%",
        zIndex: 1,
        "& img": {
            width: 80
        },
        [theme.breakpoints.down("sm")]: {
            "& img": {
                width: 50
            }
        }
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content"
    },
    formControl: {
        // marginTop: theme.spacing(2),
        minWidth: 120
    },
    // formControlLabel: {
    //     marginTop: theme.spacing(1),
    // },
    button: {
        margin: theme.spacing(1)
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    wrapper: {
        // display: none
    },
    localPlace: {
        position: "relative",
        // height: '100%',
        background: "#525050",
        paddingTop: "calc(56.25% + 56px)",
        [theme.breakpoints.down("sm")]: {
            paddingTop: "calc(67.25% + 42px)"
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: "calc(56.25% + 42px)"
        },
        // marginBottom: 56,
        overflowY: "hidden"
    },
    noVideoLocalPlace: {
        position: "relative",
        // height: '100%',
        background: "#525050",
        // paddingTop: 100,
        // [theme.breakpoints.down("sm")]: {
        //     paddingTop: 100
        // },
        // [theme.breakpoints.down("xs")]: {
        //     paddingTop: 100
        // },
        // marginBottom: 56,
        overflowY: "hidden"
    },
    videoMuteArrow: {
        position: "absolute",
        right: 0,
        background: "white",
        bottom: "6px",
        borderRadius: "50%",
        "&:hover": {
            background: "#d3cece"
        }
    },
    bigVideo: {
        width: "calc(100% - 112px)",
        height: "calc(100% - 56px)",
        [theme.breakpoints.down("xs")]: {
            height: "calc(100% - 42px)"
        },
        position: "absolute",
        top: 0,
        left: 0
    },
    sidePlace: {
        position: "absolute",
        top: 0,
        right: 0,
        width: props => (props.isMosaic ? "100%" : "100%"),
        height: "calc(100% - 84px)",
        overflowY: "auto",
        overflowX: props => (props.isSidePlaceShow ? "visible" : "hidden"),
        "& .sideWrapper": {
            position: "absolute",
            width: props => (props.isMosaic ? "100%" : "10%"),
            right: 0,
            transition: "right .3s ease-in",
            minWidth: 112
        },
        "& .ps": {
            overflow: "unset !important"
        }
    },
    participantContainer: {
        display: "block",
        position: "relative",
        borderRadius: 4,
        cursor: "pointer",
        background: common.black,
        margin: 2,
        marginTop: 6
    },
    participantContainerFocused: {
        border: "2px solid",
        borderColor: "#165ecc",
        boxShadow: "inset 0 0 3px #165ecc, 0 0 3px #165ecc"
    },
    video: {
        width: "100%",
        borderRadius: 4,
        height: "100%",
        top: 0,
        left: 0,
        position: props => (props.isMosaic ? "absolute" : ""),
        marginTop: props => (props.isMosaic ? 0 : "-75%")
    },
    remotePlace: {
        position: "relative",
        padding: "10px",
        width: "100%",
        display: props => (props.isMosaic ? "flex" : ""),
        flexWrap: "wrap",
        justifyContent: "center"
    },
    chatContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "calc(100% - 56px)",
        zIndex: 1,
        [theme.breakpoints.down("xs")]: {
            height: "calc(100% - 42px)"
        }
    },
    badge: {
        margin: theme.spacing(1)
    },
    mosaicPlace: {},
    mosaicOverlay: {
        background: "#525050",
        width: "100%",
        // zIndex: 500,
        height: "100%",
        display: "block",
        position: "absolute",
        opacity: 1,
        top: 0
    },
    mosaicRemoteContainer: {
        // top: "calc(50% - 56px)",
        // transform: "translateY(-50%)",
        top: 0,
        bottom: 56,
        right: 0,
        width: "100%",
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
            bottom: 42
        }
    },
    videoContainer: {
        display: "flex",
        paddingTop: props => (props.isMosaic ? "56.25%" : "75%")
    },
    noVideo: {
        display: "none"
    },
    mosaicVideo: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        borderRadius: "4px",
        border: "solid 2px"
    },
    hand: {
        position: "absolute",
        right: 0,
        top: 0,
        color: "white",
        background: "blue",
        border: "1px solid",
        borderRadius: "50%",
        padding: 2,
        display: "flex",
        margin: "5px",
        "& i": {
            fontSize: "0.8rem"
        }
    },
    hiddenParticipant: {
        display: "none"
    },
    toolBoxWrapper: {
        display: "flex",
        position: "relative"
    },
    extraVideo: {
        height: 500,
        [theme.breakpoints.down("sm")]: {
            height: 200
        }
    }
}));
