import { apiGetRequest, apiPostRequest } from "./helper";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const MESSAGE_URL = BASE_URL + "messages";

export function get_messages() {
    return apiGetRequest(MESSAGE_URL);
}

export function update_message(id, newMessage) {
    return apiPostRequest(MESSAGE_URL + `/${id}`, newMessage);
}

export function add_message(message) {
    return apiPostRequest(BASE_URL + `create_message`, message);
}

export function remove_message(id) {
    return apiPostRequest(MESSAGE_URL + `/${id}/remove`);
}
