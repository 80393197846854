import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import clsx from "clsx";
import * as authStore from "../../store/ducks/auth.duck";

function Security(props) {
    const { intl, firstLogin, resetPassword, resetPersionalPassword, cancelResetPassword } = props;

    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });

    useEffect(() => {
        if (firstLogin) {
            console.log("firstLogin");
        }

        return () => {
            cancelResetPassword();
        };
    }, [firstLogin, cancelResetPassword]);

    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    function handleCanel(e) {
        cancelResetPassword();
        e.preventDefault();
        props.history.push("/events");
    }
    return (
        <>
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="kt-portlet kt-portlet--height-fluid">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        {intl.formatMessage({
                                            id: "PROFILE.SECURITY.CONTAINER.TITLE"
                                        })}
                                        <small>
                                            {intl.formatMessage({
                                                id: "PROFILE.SECURITY.CONTAINER.SUBTITLE"
                                            })}
                                        </small>
                                    </h3>
                                </div>
                            </div>
                            <Formik
                                initialValues={
                                    props.firstLogin
                                        ? {
                                              password: "",
                                              confirmPassword: ""
                                          }
                                        : {}
                                }
                                initialStatus={""}
                                enableReinitialize={true}
                                validate={values => {
                                    const errors = {};

                                    if (props.firstLogin) {
                                        if (!values.password) {
                                            errors.password = intl.formatMessage({
                                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                            });
                                        }

                                        if (
                                            !/^(?=.*[a-z]){1,}(?=.*[A-Z]){1,}(?=.*[0-9]){1,}(?=.*[!@#$%^&*()--__+.]){1,}.{8,}$/i.test(
                                                values.password
                                            )
                                        ) {
                                            errors.password = intl.formatMessage({
                                                id: "AUTH.VALIDATION.PASSWORD_STRONG"
                                            });
                                        }

                                        if (!values.password_confirmation) {
                                            errors.password_confirmation = intl.formatMessage({
                                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                            });
                                        } else if (
                                            values.password !== values.password_confirmation
                                        ) {
                                            errors.password_confirmation =
                                                "Password and Confirm Password didn't match.";
                                        }
                                    }

                                    return errors;
                                }}
                                onSubmit={(values, { setStatus, setSubmitting }) => {
                                    setSubmitting(true);
                                    enableLoading();
                                    setTimeout(() => {
                                        if (props.firstLogin) {
                                            resetPersionalPassword(values.password);
                                        } else {
                                            resetPassword();
                                        }
                                        disableLoading();
                                        setSubmitting(false);
                                        setStatus(
                                            intl.formatMessage({
                                                id: "PROFILE.SECURITY.CONTAINER.SUCCESS"
                                            })
                                        );
                                    }, 1000);
                                }}
                            >
                                {({
                                    values,
                                    status,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting
                                }) => (
                                    <form
                                        noValidate={true}
                                        autoComplete="off"
                                        className="kt-form kt-form--label-right"
                                        onSubmit={handleSubmit}
                                    >
                                        <div
                                            role="alert"
                                            className="alert alert-custom alert-danger fade show mb-10 m-4"
                                        >
                                            <div className="alert-text">
                                                {intl.formatMessage({
                                                    id: "PROFILE.SECURITY.CONTAINER.NOTICE"
                                                })}
                                            </div>
                                        </div>
                                        {status && (
                                            <div role="alert" className="alert alert-success">
                                                <div className="alert-text">{status}</div>
                                            </div>
                                        )}
                                        {props.firstLogin && (
                                            <>
                                                <div
                                                    className={`form-group row ${Boolean(
                                                        touched.password && errors.password
                                                    ) && "validated"}`}
                                                >
                                                    <label className="col-xl-3 col-lg-3 col-form-label">
                                                        {intl.formatMessage({
                                                            id: "PROFILE.SECURITY.NEW_PASSWORD"
                                                        })}
                                                    </label>
                                                    <div className="col-lg-9 col-xl-6">
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            className={`form-control ${Boolean(
                                                                touched.password && errors.password
                                                            ) && "is-invalid"}`}
                                                            value={values.password}
                                                            placeholder={intl.formatMessage({
                                                                id: "PROFILE.SECURITY.NEW_PASSWORD"
                                                            })}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {Boolean(
                                                            touched.password && errors.password
                                                        ) && (
                                                            <div className="invalid-feedback">
                                                                {errors.password}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={`form-group form-group-last row ${Boolean(
                                                        touched.password_confirmation &&
                                                            errors.password_confirmation
                                                    ) && "validated"}`}
                                                >
                                                    <label className="col-xl-3 col-lg-3 col-form-label">
                                                        {intl.formatMessage({
                                                            id: "PROFILE.SECURITY.VERIFY_PASSWORD"
                                                        })}
                                                    </label>
                                                    <div className="col-lg-9 col-xl-6">
                                                        <input
                                                            type="password"
                                                            name="password_confirmation"
                                                            className={`form-control ${Boolean(
                                                                touched.password_confirmation &&
                                                                    errors.password_confirmation
                                                            ) && "is-invalid"}`}
                                                            value={values.password_confirmation}
                                                            placeholder={intl.formatMessage({
                                                                id:
                                                                    "PROFILE.SECURITY.VERIFY_PASSWORD"
                                                            })}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {Boolean(
                                                            touched.password_confirmation &&
                                                                errors.password_confirmation
                                                        ) && (
                                                            <div className="invalid-feedback">
                                                                {errors.password_confirmation}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-3 col-xl-3">
                                                        {props.firstLogin && (
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={handleCanel}
                                                            >
                                                                Cancel
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="col-lg-9 col-xl-9">
                                                        <button
                                                            disabled={isSubmitting}
                                                            className={`btn btn-brand btn-bold kt-login__btn-primary ${clsx(
                                                                {
                                                                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                                                }
                                                            )}`}
                                                            style={loadingButtonStyle}
                                                        >
                                                            {intl.formatMessage({
                                                                id:
                                                                    "PROFILE.SECURITY.CONTAINER.TITLE"
                                                            })}
                                                        </button>
                                                        &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        firstLogin: state.auth.firstLogin
    };
};

const mapDispatchToProps = dispatch => ({
    resetPassword: () => dispatch(authStore.actions.resetPassword()),
    resetPersionalPassword: password =>
        dispatch(authStore.actions.resetPersionalPassword(password)),
    cancelResetPassword: () => dispatch(authStore.actions.cancelResetPassword())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withRouter(Security)));
