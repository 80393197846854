import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
// import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";

import { UserRole } from "../../components/utils/UserRole";
import Events from "./superadmins/events/Events";
import Settings from "./superadmins/settings/Settings";
import Users from "./superadmins/users/Users";
import Profile from "../profile/Profile";

const GoogleMaterialPage = lazy(() => import("./google-material/GoogleMaterialPage"));
const ReactBootstrapPage = lazy(() => import("./react-bootstrap/ReactBootstrapPage"));

function HomePage(props) {
    const { user } = props;
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {/* Redirect from root URL to /dashboard. */
                user && user.role === UserRole.ADMIN ? (
                    <Redirect exact from="/" to="/events" />
                ) : (
                    <Redirect exact from="/" to="/dashboard" />
                )}
                <Route path="/events" component={Events} />
                <Route path="/settings" component={Settings} />
                <Route path="/users" component={Users} />
                <Route path="/profile" component={Profile} />

                {user && user.role === UserRole.SUPERADMIN ? (
                    <>
                        <Route path="/builder" component={Builder} />
                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/google-material" component={GoogleMaterialPage} />
                        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
                    </>
                ) : null}

                {/* <Route path="/docs" component={DocsPage} /> */}
                <Redirect to="/error/error-v1" />
            </Switch>
        </Suspense>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => ({});
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePage)));
