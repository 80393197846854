import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    IconButton
} from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DoneIcon from "@material-ui/icons/Done";

import * as customersStore from "../../../../store/ducks/customers.duck";
import generateString from "../../../../components/utils/GenerateRandomChars";

function CustomerResetPasswordDlg(props) {
    const { onClose, open, customerId, dispatch, intl } = props;

    const [password, setPassword] = useState("");
    const [isCopied, setCopied] = React.useState(false);

    const message = useSelector(state => state.customers.message);

    const handleClose = () => {
        setPassword("");
        onClose();
    };

    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });
    // Submit Button Effects
    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };
    // Handle Change Customer Password
    const handlePasswordChange = e => {
        const newValue = e.target.value;
        setPassword(newValue);
    };

    const handlePasswordGenerate = () => {
        const password = generateString(10);
        setPassword(password);
    };

    const handleCopyUrl = () => {
        navigator.clipboard.writeText(password);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {intl.formatMessage({ id: "CUSTOMER_PASSWORD.DLG.TITLE" })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {intl.formatMessage({ id: "CUSTOMER_PASSWORD.DLG.DESC" })}
                </DialogContentText>
                <Formik
                    initialValues={{ password: password }}
                    initialStatus={message}
                    enableReinitialize
                    validate={values => {
                        const errors = {};

                        if (!values.password) {
                            errors.password = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else {
                            if (values.password.length < 6) {
                                errors.password = intl.formatMessage(
                                    {
                                        id: "AUTH.VALIDATION.MIN_LENGTH"
                                    },
                                    { name: "Password", min: 6 }
                                );
                            }
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        enableLoading();
                        setTimeout(() => {
                            if (customerId !== -1) {
                                dispatch(
                                    customersStore.actions.updateCustomerPassword(
                                        customerId,
                                        password
                                    )
                                );
                                disableLoading();
                            }
                        }, 1000);
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form"
                            onSubmit={handleSubmit}
                        >
                            {status ? (
                                <div
                                    role="alert"
                                    className={`alert alert-${status.status ?? "danger"}`}
                                >
                                    <div className="alert-text">{status.content ?? ""}</div>
                                </div>
                            ) : (
                                ""
                            )}

                            <div className="d-flex">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "CUSTOMER_PASSWORD.DLG.FIELD.PASSWORD"
                                    })}
                                    className="kt-width-full"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handlePasswordChange}
                                    value={values.password}
                                    helperText={touched.password && errors.password}
                                    error={Boolean(touched.password && errors.password)}
                                />
                                <IconButton
                                    aria-label="Reset Password"
                                    color="primary"
                                    onClick={event => handlePasswordGenerate()}
                                    style={{
                                        height: "100%",
                                        alignSelf: "flex-end"
                                    }}
                                >
                                    <AutorenewIcon />
                                </IconButton>
                                <IconButton
                                    variant="contained"
                                    disabled={false}
                                    // className={classes.button}
                                    onClick={handleCopyUrl}
                                    color="primary"
                                    style={{
                                        height: "100%",
                                        alignSelf: "flex-end"
                                    }}
                                >
                                    {isCopied ? <DoneIcon /> : <FileCopyIcon />}
                                </IconButton>
                            </div>
                            <div className="kt-login__actions">
                                <DialogActions>
                                    <Button color="primary" onClick={handleClose}>
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.CANCEL"
                                        })}
                                    </Button>
                                    <button
                                        id="kt_login_signin_submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                            {
                                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                            }
                                        )}`}
                                        style={loadingButtonStyle}
                                    >
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.SUBMIT"
                                        })}
                                    </button>
                                </DialogActions>
                            </div>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

CustomerResetPasswordDlg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    customerId: PropTypes.any
};

export default injectIntl(connect()(CustomerResetPasswordDlg));
