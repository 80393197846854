import { apiGetRequest, apiPostRequest } from "./helper";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const LANGUAGE_URL = BASE_URL + "language_list";
export const LANGUAGE_EDIT_URL = BASE_URL + "languages";

export function get_languages() {
    return apiGetRequest(LANGUAGE_URL);
}

export function get_language(id) {
    return apiGetRequest(LANGUAGE_EDIT_URL + `/${id}`);
}

export function update_language(id, newChannel) {
    return apiPostRequest(LANGUAGE_EDIT_URL + `/${id}`, newChannel);
}

export function add_language(language) {
    return apiPostRequest(BASE_URL + `create_language`, language);
}

export function remove_language(id) {
    return apiPostRequest(BASE_URL + `delete_language/${id}`);
}

export function get_language_flags() {
    return apiGetRequest(BASE_URL + "language_flags");
}
