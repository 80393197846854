import { apiGetRequest, apiPostRequest } from "./helper";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const EMITTER_URL = BASE_URL + "emitter_list";
export const EMITTER_EDIT_URL = BASE_URL + "emitters";

export function get_emitters() {
    return apiGetRequest(EMITTER_URL);
}

export function get_emitter(id) {
    return apiGetRequest(EMITTER_EDIT_URL + `/${id}`);
}

export function update_emitter(id, newEmitter) {
    return apiPostRequest(EMITTER_EDIT_URL + `/${id}`, newEmitter);
}

export function add_emitter(emitter) {
    return apiPostRequest(BASE_URL + `create_emitter`, emitter);
}

export function remove_emitter(id) {
    return apiPostRequest(BASE_URL + `delete_emitter/${id}`);
}
