import React from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles, LinearProgress, Paper } from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: "absolute",
        width: 250,
        // backgroundColor: theme.palette.common.white,
        // borderRadius: 4,
        // transform: props => { return `translate3d(0px, ${_getInterpreters(props.participants).length * -40}px, 0px)` },
        // boxShadow: '0px 0px 50px 0px rgba(82, 63, 105, 0.15)',
        // transform: "rotate(270deg)",
        // left: -15,
        marginTop: "-100%",
        padding: 5
    },
    volume: {
        display: "inline-block",
        width: 20,
        textAlign: "center"
    }
}));

const BorderLinearProgress = withStyles(() => ({
    root: {
        margin: 5,
        display: "inline-block",
        width: "calc(100% - 40px)",
        verticalAlign: "middle"
    },
    colorPrimary: {
        backgroundColor: "#22246254"
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#1a90ff"
    }
}))(LinearProgress);

function MicIndicator(props) {
    const { audioLevel, isMicOn, isCheckMic } = props;
    const classes = useStyles(props);

    return (
        isCheckMic && (
            <Paper className={classes.wrapper}>
                {isMicOn ? <MicIcon /> : <MicOffIcon />}
                <BorderLinearProgress
                    className={classes.linearProgress}
                    variant="determinate"
                    value={audioLevel}
                />
            </Paper>
        )
    );
}

const mapStateToProps = state => {
    return {
        isCheckMic: state.event.isCheckMic,
        volume: state.event.volume,
        isMicOn: state.event.isMicOn,
        isMuted: state.event.isMuted
    };
};

const mapDispatchToProps = dispatch => ({
    // hideNotification: () => dispatch(eventStore.actions.hideNotification())
});

export default connect(mapStateToProps, mapDispatchToProps)(MicIndicator);
