import { UserRole } from "./utils/UserRole";

export class AudioMixer {
    constructor() {
        this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        this.gainNode = this.audioCtx.createGain();
        this.bassFilter = this.audioCtx.createBiquadFilter();
        this.trebleFilter = this.audioCtx.createBiquadFilter();

        this.streamsToMix = [];
        this.channelMerger = null;
        this.channelSplitter = null;
        this.castAudioCtx = null;
        this.castSource = null;
        this.webCast = null;
        this.bass = 4;
        this.treble = 4;
    }

    playFilter(room, isAddFloor) {
        return;
        if (this.channelMerger) {
            this.channelMerger.disconnect();
        }

        if (this.bassFilter) {
            this.trebleFilter.disconnect();
        }

        if (this.trebleFilter) {
            this.trebleFilter.disconnect();
        }

        if (this.channelSplitter) {
            this.channelSplitter.disconnect();
        }

        if (this.gainNode) {
            this.gainNode.disconnect();
        }

        if (this.streamsToMix.length === 0) {
            return;
        }

        this.channelMerger = this.audioCtx.createChannelMerger(this.streamsToMix.length);
        this.channelSplitter = this.audioCtx.createChannelSplitter(2);

        for (const trackStream of this.streamsToMix) {
            if (trackStream.stream.getAudioTracks().length > 0) {
                const streamMSS = this.audioCtx.createMediaStreamSource(trackStream.stream);
                const participantId = trackStream.getParticipantId();
                const participant = room.getParticipantById(participantId);
                if (isAddFloor) {
                    if (
                        participant &&
                        parseInt(participant.getProperty("role")) === UserRole.INTERPRETER
                    ) {
                        console.log("translator track");
                        streamMSS.connect(this.channelMerger);
                    } else {
                        const partGainNode = this.audioCtx.createGain();
                        partGainNode.gain.value = 1;
                        console.log("floor track");
                        streamMSS.connect(partGainNode);
                        partGainNode.connect(this.channelMerger);
                        console.log(partGainNode);
                    }
                } else {
                    streamMSS.connect(this.channelMerger);
                }
            }
        }

        this.bassFilter.type = "lowshelf";
        this.bassFilter.frequency.value = 200;
        this.bassFilter.gain.value = this.bass;

        this.trebleFilter.type = "highshelf";
        this.trebleFilter.frequency.value = 2000;
        this.trebleFilter.gain.value = this.treble;

        this.channelMerger.connect(this.bassFilter);
        this.bassFilter.connect(this.trebleFilter);
        this.trebleFilter.connect(this.channelSplitter);
        this.channelSplitter.connect(this.gainNode, 0);
        this.gainNode.connect(this.audioCtx.destination);
    }

    addMediaTrackStream(trackStream) {
        return;
        this.streamsToMix.push(trackStream);
    }
}
