import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import {
    colors,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";

import * as adminsStore from "../../../../store/ducks/admins.duck";

const useDlgStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: colors.blue[100],
        color: colors.blue[600]
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 400
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 400
        }
    }
};

function AdminDlg(props) {
    const { onClose, open, adminId, adminValue, channels, dispatch, intl } = props;

    const [admin, setAdmin] = useState({ name: "", email: "" });
    const [channelArray, setChannelArray] = useState([channels.map(a => a.channel_name)]);

    const classes = useDlgStyles();

    useEffect(() => {
        if (open) {
            if (channels.length > 0) {
                setChannelArray(channels.map(a => a.channel_name));
            }

            if (adminId && adminId !== -1 && adminValue) {
                setAdmin(adminValue);
            }
        }
    }, [open, adminId, channels, adminValue]);

    const handleClose = () => {
        setAdmin({ name: "", email: "" });
        onClose();
    };

    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });
    // Submit Button Effects
    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {intl.formatMessage({ id: "ADMIN.DETAIL.DLG.TITLE" })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{intl.formatMessage({ id: "EVENTDLG.DESC" })}</DialogContentText>
                <Formik
                    initialValues={admin}
                    enableReinitialize
                    validate={values => {
                        const errors = {};

                        if (!values.name) {
                            errors.name = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.email) {
                            errors.email = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        enableLoading();
                        setTimeout(() => {
                            if (adminId !== -1) {
                                dispatch(
                                    adminsStore.actions.updateAdmins(
                                        Object.assign(adminValue, values)
                                    )
                                );
                                disableLoading();
                                onClose();
                            } else {
                                dispatch(adminsStore.actions.addAdmin(values));
                                disableLoading();
                                onClose();
                            }
                        }, 1000);
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form"
                            onSubmit={handleSubmit}
                        >
                            {status ? (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            ) : (
                                <></>
                            )}

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "USER.FIELD.NAME"
                                    })}
                                    className="kt-width-full"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    helperText={touched.name && errors.name}
                                    error={Boolean(touched.name && errors.name)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "USER.FIELD.EMAIL"
                                    })}
                                    className="kt-width-full"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(touched.email && errors.email)}
                                />
                            </div>

                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-mutiple-checkbox-label">
                                    {intl.formatMessage({
                                        id: "ADMIN.DETAIL.DLG.CHANNEL"
                                    })}
                                </InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={values.admin_contracts ? values.admin_contracts : []}
                                    onChange={handleChange}
                                    input={<Input />}
                                    name="admin_contracts"
                                    renderValue={selected => selected.join(", ")}
                                    MenuProps={MenuProps}
                                >
                                    {channelArray.map(row => (
                                        <MenuItem key={row} value={row}>
                                            <Checkbox
                                                checked={
                                                    values.admin_contracts
                                                        ? values.admin_contracts.indexOf(row) > -1
                                                        : false
                                                }
                                            />
                                            <ListItemText primary={row} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <div className="kt-login__actions">
                                <DialogActions>
                                    <Button color="primary" onClick={handleClose}>
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.CANCEL"
                                        })}
                                    </Button>
                                    <button
                                        id="kt_login_signin_submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                            {
                                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                            }
                                        )}`}
                                        style={loadingButtonStyle}
                                    >
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.SUBMIT"
                                        })}
                                    </button>
                                </DialogActions>
                            </div>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

AdminDlg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    adminId: PropTypes.any
};

export default injectIntl(connect()(AdminDlg));
