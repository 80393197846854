import { UserRole } from "./UserRole";

export const options = {
    hosts: {
        domain: process.env.REACT_APP_DOMAIN,
        muc: process.env.REACT_APP_MUC // FIXME: use XEP-0030
    },
    serviceUrl: process.env.REACT_APP_SERVICE_URL, // FIXME: use xep-0156 for that

    // The name of client node advertised in XEP-0115 'c' stanza
    clientNode: process.env.REACT_APP_CLIENT_NODE,
    channelLastN: -1
};

export const initOptions = {
    disableAudioLevels: false,

    // The ID of the jidesha extension for Chrome.
    desktopSharingChromeExtId: "mbocklcggfhnbahlnepmldehdhpjfcjp",

    // Whether desktop sharing should be disabled on Chrome.
    desktopSharingChromeDisabled: false,

    // The media sources to use when using screen sharing with the Chrome
    // extension.
    desktopSharingChromeSources: ["screen", "window"],

    // Required version of Chrome extension
    desktopSharingChromeMinExtVersion: "0.1",

    // Whether desktop sharing should be disabled on Firefox.
    desktopSharingFirefoxDisabled: false,
    enableAnalyticsLogging: true,

    disableSimulcast: false,
    resolution: 720,

    // w3c spec-compliant video constraints to use for video capture. Currently
    // used by browsers that return true from lib-jitsi-meet's
    // util#browser#usesNewGumFlow. The constraints are independency from
    // this config's resolution value. Defaults to requesting an ideal aspect
    // ratio of 16:9 with an ideal resolution of 720.
    constraints: {
        video: {
            aspectRatio: 16 / 9,
            height: {
                ideal: 720,
                max: 1080,
                min: 720
            }
        }
    },
    disableAP: true,
    disableAEC: true,
    disableNS: true,
    disableAGC: true,
    disableHPF: true,
    stereo: true
};

export const confOptions = {
    openBridgeChannel: "websocket",
    statisticsId: null,
    p2p: {
        enabled: false
    }
};

/**
 * Check if the participant is interpreter or not.
 * @param {*} room
 * @param {*} participantId
 */
export function isInterpreter(participants, participantId) {
    for (let j = 0; j < participants.length; j++) {
        if (participants[j].getId() === participantId) {
            if (parseInt(participants[j].getProperty("role")) === UserRole.INTERPRETER) {
                return true;
            }
        }
    }
    return false;
}

export function compare(p, p1) {
    if (p.getDisplayName() < p1.getDisplayName()) {
        return -1;
    }
    if (p.getDisplayName() > p1.getDisplayName()) {
        return 1;
    }
    return 0;
}
