import React from "react";
import { connect } from "react-redux";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import * as eventStore from "../store/ducks/event.duck";

function Notify(props) {
    const { isNotification, notifyType, notifyContent, hideNotification } = props;

    function handleClose() {
        hideNotification();
    }
    return (
        isNotification && (
            <Snackbar
                open={isNotification}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={60000}
                onClose={handleClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity={notifyType}
                    onClose={handleClose}
                    style={{ fontSize: "2rem", fontWeight: 700 }}
                >
                    {notifyContent}
                </MuiAlert>
            </Snackbar>
        )
    );
}

const mapStateToProps = state => {
    return {
        isNotification: state.event.isNotification,
        notifyType: state.event.type,
        notifyContent: state.event.content
    };
};

const mapDispatchToProps = dispatch => ({
    hideNotification: () => dispatch(eventStore.actions.hideNotification())
});

export default connect(mapStateToProps, mapDispatchToProps)(Notify);
