const menuConfig = {
    general: {
        header: {
            self: {},
            items: []
        },
        aside: {
            self: {},
            items: []
        }
    },

    superAdmin: {
        header: {
            self: {},
            items: [
                {
                    title: "Dashboard",
                    root: true,
                    icon: "flaticon2-architecture-and-city",
                    page: "dashboard",
                    translate: "MENU.DASHBOARD",
                    bullet: "dot"
                },
                {
                    title: "Events",
                    root: true,
                    icon: "flaticon2-calendar-2",
                    page: "events",
                    translate: "MENU.EVENTS",
                    bullet: "dot"
                },
                {
                    title: "Users",
                    root: true,
                    bullet: "dot",
                    icon: "flaticon2-user",
                    translate: "MENU.USERS",
                    submenu: [
                        {
                            title: "Admins",
                            page: "users/admins",
                            translate: "MENU.USERS.ADMINS"
                        },
                        {
                            title: "Event Managers",
                            page: "users/managers",
                            translate: "MENU.USERS.MANAGERS"
                        },
                        {
                            title: "Emitters",
                            page: "users/emitters",
                            translate: "MENU.USERS.EMITTERS"
                        },
                        {
                            title: "Interpreters",
                            page: "users/interpreters",
                            translate: "MENU.USERS.INTERPRETERS"
                        },
                        {
                            title: "Customers",
                            page: "users/customers",
                            translate: "MENU.USERS.CUSTOMERS"
                        }
                    ]
                },
                {
                    title: "Settings",
                    root: true,
                    bullet: "dot",
                    translate: "MENU.SETTINGS",
                    icon: "flaticon2-settings",
                    submenu: [
                        {
                            title: "Channels",
                            page: "settings/channels",
                            translate: "MENU.SETTINGS.CHANNELS"
                        },
                        {
                            title: "Languages",
                            page: "settings/languages",
                            translate: "MENU.SETTINGS.LANGUAGES"
                        },
                        {
                            title: "Messages",
                            page: "settings/messages",
                            translate: "MENU.SETTINGS.MESSAGES"
                        }
                    ]
                }
            ]
        },
        aside: {
            self: {},
            items: [
                {
                    title: "Dashboard",
                    root: true,
                    icon: "flaticon2-architecture-and-city",
                    page: "dashboard",
                    translate: "MENU.DASHBOARD",
                    bullet: "dot"
                },
                {
                    title: "Events",
                    root: true,
                    icon: "flaticon2-architecture-and-city",
                    page: "events",
                    translate: "MENU.EVENTS",
                    bullet: "dot"
                },
                {
                    title: "Users",
                    root: true,
                    bullet: "dot",
                    icon: "flaticon2-user",
                    translate: "MENU.USERS",
                    submenu: [
                        {
                            title: "Admins",
                            page: "users/admins",
                            translate: "MENU.USERS.ADMINS"
                        },
                        {
                            title: "Event Managers",
                            page: "users/managers",
                            translate: "MENU.USERS.MANAGERS"
                        },
                        {
                            title: "Emitters",
                            page: "users/emitters",
                            translate: "MENU.USERS.EMITTERS"
                        },
                        {
                            title: "Interpreters",
                            page: "users/interpreters",
                            translate: "MENU.USERS.INTERPRETERS"
                        },
                        {
                            title: "Customers",
                            page: "users/customers",
                            translate: "MENU.USERS.CUSTOMERS"
                        }
                    ]
                },
                {
                    title: "Settings",
                    root: true,
                    bullet: "dot",
                    translate: "MENU.SETTINGS",
                    icon: "flaticon2-settings",
                    submenu: [
                        {
                            title: "Channels",
                            page: "settings/channels",
                            translate: "MENU.SETTINGS.CHANNELS"
                        },
                        {
                            title: "Languages",
                            page: "settings/languages",
                            translate: "MENU.SETTINGS.LANGUAGES"
                        }
                    ]
                },
                {
                    title: "Error Pages",
                    root: true,
                    bullet: "dot",
                    icon: "flaticon-danger",
                    submenu: [
                        {
                            title: "Error Page - 1",
                            page: "error/error-v1"
                        },
                        {
                            title: "Error Page - 2",
                            page: "error/error-v2"
                        },
                        {
                            title: "Error Page - 3",
                            page: "error/error-v3"
                        },
                        {
                            title: "Error Page - 4",
                            page: "error/error-v4"
                        },
                        {
                            title: "Error Page - 5",
                            page: "error/error-v5"
                        },
                        {
                            title: "Error Page - 6",
                            page: "error/error-v6"
                        }
                    ]
                }
            ]
        }
    },
    admin: {
        header: {
            self: {},
            items: [
                {
                    title: "Events",
                    root: true,
                    icon: "flaticon2-calendar-2",
                    page: "events",
                    translate: "MENU.EVENTS",
                    bullet: "dot"
                },
                {
                    title: "Users",
                    root: true,
                    bullet: "dot",
                    icon: "flaticon2-user",
                    translate: "MENU.USERS",
                    submenu: [
                        {
                            title: "Event Managers",
                            page: "users/managers",
                            translate: "MENU.USERS.MANAGERS"
                        },
                        {
                            title: "Emitters",
                            page: "users/emitters",
                            translate: "MENU.USERS.EMITTERS"
                        },
                        {
                            title: "Interpreters",
                            page: "users/interpreters",
                            translate: "MENU.USERS.INTERPRETERS"
                        },
                        {
                            title: "Customers",
                            page: "users/customers",
                            translate: "MENU.USERS.CUSTOMERS"
                        }
                    ]
                },
                {
                    title: "Settings",
                    root: true,
                    bullet: "dot",
                    translate: "MENU.SETTINGS",
                    icon: "flaticon2-settings",
                    submenu: [
                        {
                            title: "Messages",
                            page: "settings/messages",
                            translate: "MENU.SETTINGS.MESSAGES"
                        }
                    ]
                }
            ]
        },
        aside: {
            self: {},
            items: [
                {
                    title: "Events",
                    root: true,
                    icon: "flaticon2-architecture-and-city",
                    page: "events",
                    translate: "MENU.EVENTS",
                    bullet: "dot"
                }
            ]
        }
    },
    emitter: {
        header: {
            self: {},
            items: [
                {
                    title: "Events",
                    root: true,
                    icon: "flaticon2-calendar-2",
                    page: "events",
                    translate: "MENU.EVENTS",
                    bullet: "dot"
                }
            ]
        },
        aside: {
            self: {},
            items: [
                {
                    title: "Events",
                    root: true,
                    icon: "flaticon2-calendar-2",
                    page: "events",
                    translate: "MENU.EVENTS",
                    bullet: "dot"
                }
            ]
        }
    },
    interpreter: {
        header: {
            self: {},
            items: [
                {
                    title: "Events",
                    root: true,
                    icon: "flaticon2-calendar-2",
                    page: "events",
                    translate: "MENU.EVENTS",
                    bullet: "dot"
                }
            ]
        },
        aside: {
            self: {},
            items: [
                {
                    title: "Events",
                    root: true,
                    icon: "flaticon2-calendar-2",
                    page: "events",
                    translate: "MENU.EVENTS",
                    bullet: "dot"
                }
            ]
        }
    },
    customer: {
        header: {
            self: {},
            items: [
                {
                    title: "Events",
                    root: true,
                    icon: "flaticon2-calendar-2",
                    page: "events",
                    translate: "MENU.EVENTS",
                    bullet: "dot"
                }
            ]
        },
        aside: {
            self: {},
            items: [
                {
                    title: "Events",
                    root: true,
                    icon: "flaticon2-calendar-2",
                    page: "events",
                    translate: "MENU.EVENTS",
                    bullet: "dot"
                }
            ]
        }
    }
};

export default menuConfig;
