import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Iframe from "react-iframe";
import ToolBar from "../../../../../components/Toolbar";
import SelectDeviceDialog from "../../../../../components/SelectDeviceDialog";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import {
    options,
    initOptions,
    confOptions,
    isInterpreter
} from "../../../../../components/utils/RoomUtils";

import { isMobile } from "../../../../../components/utils/MobileDetect";
import { meetingVideo as useStyles } from "../../../../../components/CommonStyles";
import $ from "jquery";

import * as eventStore from "../../../../../store/ducks/event.duck";
import { UserRole } from "../../../../../components/utils/UserRole";
import MeetContainer from "../../../../../components/MeetContainer";
import { EventType } from "../../../../../components/utils/EventType";
import { ORIGINAL_ROOMNAME } from "../../../../../components/JitsiMeeting";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const JitsiMeetJS = window.JitsiMeetJS;

function ConferenceVideo(props) {
    const {
        event,
        isEndMeeting,
        endMeetingSuccess,
        roomname,
        volume,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        user,
        authToken,
        addStat,
        startStatId,
        showNotification,
        isOriginal,
        isSubTitle,
        intl
    } = props;

    const classes = useStyles(props);

    const [audioOutputSetting, setAudioOutputSetting] = React.useState("");
    const [audioOutputDevices, setAudioOutputDevices] = React.useState([]);
    const connection = React.useRef(null);
    const isJoined = React.useRef(false);
    const room = React.useRef(null);
    const remoteTracks = React.useRef({});
    const currentRoomname = React.useRef(null);
    const isWithFloor = React.useRef(null);

    const audioCtx = React.useRef(null);
    const gainNode = React.useRef(null);
    const bassFilter = React.useRef(null);
    const trebleFilter = React.useRef(null);
    const streamsToMix = React.useRef([]);
    const channelMerger = React.useRef(null);
    const channelSplitter = React.useRef(null);

    const castAudioCtx = React.useRef(null);
    const castSource = React.useRef(null);
    const webCast = React.useRef(null);

    const audioMixer = React.useRef(null);

    useEffect(() => {
        if (isEndMeeting) {
            postStat(2);
            endMeetingSuccess();
        } else {
            startJitSiMeeting();
        }
    }, [isEndMeeting]);

    useEffect(() => {
        audioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
        console.log("🚀 ~ useEffect ~ audioCtx.current:", audioCtx.current);
        gainNode.current = audioCtx.current.createGain();
        bassFilter.current = audioCtx.current.createBiquadFilter();
        trebleFilter.current = audioCtx.current.createBiquadFilter();

        castAudioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
        console.log("🚀 ~ useEffect ~ castAudioCtx.current:", castAudioCtx.current);

        // webCast.current = castAudioCtx.current.createWebcastSource(4096, 2);

        return () => {
            if (connection) {
                unload();
            }

            if (webCast.current) {
                webCast.current.close(function() {
                    console.log("left");
                });
            }

            endMeetingSuccess();
        };
    }, []);

    useEffect(() => {
        if (room.current) {
            currentRoomname.current = roomname;
            changeLangTrack(roomname);

            postStat(3);
            setRoomFeature();
        }
    }, [roomname]);

    useEffect(() => {
        if (room.current) {
            $("audio").prop("volume", volume);

            gainNode.current.gain.value = volume;
        }
    }, [volume]);

    // useEffect(() => {
    //     if (bassFilter.current) {
    //         bassFilter.current.gain.value = bass;
    //     }
    // }, [bass])

    // useEffect(() => {
    //     if (trebleFilter.current) {
    //         trebleFilter.current.gain.value = treble;
    //     }
    // }, [treble])

    useEffect(() => {}, [startStatId]);

    useEffect(() => {
        isWithFloor.current = isOriginal;

        changeLangTrack(currentRoomname.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOriginal]);

    function changeLangTrack(newRoomName) {
        if (!room.current) {
            return;
        }
        const participants = room.current.getParticipants();
        let interpreter_ids = [];
        let disabled_interpreter = [];

        for (let j = 0; j < participants.length; j++) {
            if (participants[j].getProperty("output") === newRoomName) {
                interpreter_ids.push(participants[j].getId());
            }

            if (participants[j].getProperty("output") === `non-${currentRoomname.current}`) {
                disabled_interpreter.push(participants[j].getId());
            }
        }

        _resetAudioInputs([]);

        streamsToMix.current = [];
        if (channelMerger.current) {
            channelMerger.current.disconnect();
        }

        if (newRoomName === ORIGINAL_ROOMNAME) {
            for (let key in remoteTracks.current) {
                if (remoteTracks.current.hasOwnProperty(key)) {
                    for (let j = remoteTracks.current[key].length - 1; j >= 0; j--) {
                        if (!isInterpreter(participants, key)) {
                            const audioTrack = remoteTracks.current[key][j];
                            console.log("🚀 ~ changeLangTrack ~ audioTrack:", audioTrack);
                            if (audioTrack.getType() === "audio" && audioTrack.disposed === false) {
                                $(`#${key}Place`).append(
                                    `<audio muted autoPlay='1' id='${key}audio${j}'/>`
                                );

                                console.log(
                                    "🚀 ~ changeLangTrack ~ autoPlay:",
                                    $(`#${key}audio${j}`)
                                );

                                audioTrack.attach($(`#${key}audio${j}`)[0]);
                                // $(`#${key}audio${j}`)[0].addEventListener(
                                //     "canplaythrough",
                                //     event => {
                                //         console.log(
                                //             "🚀 ~ changeLangTrack ~ event:",
                                //             event
                                //         );
                                //         /* the audio is now playable; play it if permissions allow */

                                //         $(`#${key}audio${j}`)[0].play();
                                //     }
                                // );

                                addMediaTrackStream(remoteTracks.current[key][j]);

                                break;
                            }
                        }
                    }
                }
            }
        } else {
            interpreter_ids.forEach(interpreter_id => {
                _addParticipantAudio(interpreter_id);
            });

            if (
                isWithFloor.current ||
                (event.is_passthrough === 1 && interpreter_ids.length === 0)
            ) {
                for (let key1 in remoteTracks.current) {
                    if (remoteTracks.current.hasOwnProperty(key1)) {
                        for (let j = remoteTracks.current[key1].length - 1; j >= 0; j--) {
                            const track = remoteTracks.current[key1][j];
                            if (
                                track.getType() === "audio" &&
                                track.disposed === false &&
                                !isInterpreter(participants, key1)
                            ) {
                                $(`#${key1}Place`).append(
                                    `<audio muted autoPlay='1' id='${key1}audio${j}'/>`
                                );
                                track.attach($(`#${key1}audio${j}`)[0]);
                                const elem = document.getElementById(`${key1}audio${j}`);
                                elem.volume = volume;

                                addMediaTrackStream(remoteTracks.current[key1][j]);
                                break;
                            }
                        }
                    }
                }
            }
        }

        playFilter();
    }

    function _resetAudioInputs(exceptParticipants = []) {
        for (var key in remoteTracks.current) {
            if (
                remoteTracks.current.hasOwnProperty(key) &&
                exceptParticipants.indexOf(key) === -1
            ) {
                for (let j = 0; j < remoteTracks.current[key].length; j++) {
                    const containers = remoteTracks.current[key][j].containers;
                    if (
                        remoteTracks.current[key][j].getType() === "audio" &&
                        containers.length > 0
                    ) {
                        const container_id = containers[0].id;
                        remoteTracks.current[key][j].detach($(`#${container_id}`)[0]);
                        $(`#${container_id}`).remove();
                    }
                }
            }
        }
    }

    function _addParticipantAudio(participantId) {
        for (let j = remoteTracks.current[participantId].length - 1; j >= 0; j--) {
            if (remoteTracks.current[participantId][j].getType() === "audio") {
                $(`#${participantId}Place`).append(
                    `<audio muted autoPlay='1' id='${participantId}audio${j}'/>`
                );

                remoteTracks.current[participantId][j].attach($(`#${participantId}audio${j}`)[0]);

                addMediaTrackStream(remoteTracks.current[participantId][j]);
                break;
            }
        }
    }

    async function castAudioStream(wss_url, stream) {
        console.log(wss_url);
        if (webCast.current) {
            console.log(webCast.current);

            try {
                console.log(webCast.current.isOpen());
            } catch (error) {
                console.log(error);
            }
            await webCast.current.disconnect();
            await castSource.current.disconnect();
            await webCast.current.close(function() {
                console.log("stream connection closed!");
                console.log(stream);
                castAudioCtx.current = null;
                castSource.current = null;
                webCast.current = null;
            });
        }

        setTimeout(() => {
            console.log("wait");

            castAudioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
            webCast.current = castAudioCtx.current.createWebcastSource(4096, 2);

            castSource.current = castAudioCtx.current.createMediaStreamSource(stream);
            castSource.current.connect(webCast.current);
            webCast.current.connect(castAudioCtx.current.destination);

            var encoder = new window.Webcast.Encoder.Mp3({
                channels: 2,
                samplerate: 44100,
                bitrate: 128
            });

            if (44100 !== castAudioCtx.current.sampleRate) {
                encoder = new window.Webcast.Encoder.Resample({
                    encoder: encoder,
                    type: window.Samplerate.LINEAR,
                    samplerate: castAudioCtx.current.sampleRate
                });
            }

            encoder = new window.Webcast.Encoder.Asynchronous({
                encoder: encoder,
                scripts: [
                    "https://cdn.rawgit.com/webcast/libsamplerate.js/master/dist/libsamplerate.js",
                    "https://cdn.rawgit.com/savonet/shine/master/js/dist/libshine.js",
                    "https://cdn.rawgit.com/webcast/webcast.js/master/lib/webcast.js"
                ]
            });

            console.log(encoder);

            webCast.current.connectSocket(encoder, wss_url);

            webCast.current.sendMetadata({
                title: event.title,
                artist: `(T) ${user.name}`
            });
        }, 400);
    }

    function playFilter() {
        if (channelMerger.current) {
            channelMerger.current.disconnect();
        }

        if (bassFilter.current) {
            trebleFilter.current.disconnect();
        }

        if (trebleFilter.current) {
            trebleFilter.current.disconnect();
        }

        if (channelSplitter.current) {
            channelSplitter.current.disconnect();
        }

        if (gainNode.current) {
            gainNode.current.disconnect();
        }

        if (streamsToMix.current.length === 0) {
            return;
        }

        channelMerger.current = audioCtx.current.createChannelMerger(streamsToMix.current.length);
        channelSplitter.current = audioCtx.current.createChannelSplitter(2);

        for (const trackStream of streamsToMix.current) {
            if (trackStream.stream.getAudioTracks().length > 0) {
                const streamMSS = audioCtx.current.createMediaStreamSource(trackStream.stream);
                const participantId = trackStream.getParticipantId();
                const participant = room.current.getParticipantById(participantId);
                if (isOriginal) {
                    if (
                        participant &&
                        parseInt(participant.getProperty("role")) === UserRole.INTERPRETER
                    ) {
                        console.log("translator track");
                        streamMSS.connect(channelMerger.current);
                    } else {
                        const partGainNode = audioCtx.current.createGain();
                        partGainNode.gain.value = 0.2;
                        console.log("floor track");
                        streamMSS.connect(partGainNode);
                        partGainNode.connect(channelMerger.current);
                        console.log(partGainNode);
                    }
                } else {
                    streamMSS.connect(channelMerger.current);
                }
            }
        }

        bassFilter.current.type = "lowshelf";
        bassFilter.current.frequency.value = 200;
        // bassFilter.current.gain.value = bass;

        trebleFilter.current.type = "highshelf";
        trebleFilter.current.frequency.value = 2000;
        // trebleFilter.current.gain.value = treble;

        channelMerger.current.connect(bassFilter.current);
        bassFilter.current.connect(trebleFilter.current);
        trebleFilter.current.connect(channelSplitter.current);
        channelSplitter.current.connect(gainNode.current, 0);
        gainNode.current.connect(audioCtx.current.destination);
    }

    function addMediaTrackStream(trackStream) {
        streamsToMix.current.push(trackStream);
    }

    function startJitSiMeeting() {
        if (!event) {
            return;
        }

        currentRoomname.current = roomname;
        JitsiMeetJS.init(initOptions);
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
        const appId = process.env.REACT_APP_APP_ID;

        connection.current = new JitsiMeetJS.JitsiConnection(appId, authToken, options);

        connection.current.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            onConnectionSuccess
        );
        connection.current.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            onConnectionFailed
        );
        connection.current.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            disconnect
        );

        JitsiMeetJS.mediaDevices.addEventListener(
            JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
            onDeviceListChanged
        );

        connection.current.connect();
        postStat(0);

        audioMixer.current = JitsiMeetJS.createAudioMixer();

        let _audioOutputDevices = [];
        if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output")) {
            JitsiMeetJS.mediaDevices.enumerateDevices(devices => {
                devices.forEach((device, i) => {
                    switch (device.kind) {
                        case "audiooutput":
                            _audioOutputDevices.push(device);
                            break;
                        default:
                            break;
                    }
                });

                // TODO: Check if states are updated above
                if (_audioOutputDevices.length > 0) {
                    setAudioOutputDevices(_audioOutputDevices);
                    setAudioOutputSetting(_audioOutputDevices[0].deviceId);
                }
            });
        }
    }

    /**
     * That function is executed when the conference is joined
     */
    function onConferenceJoined() {
        isJoined.current = true;
        setRoomFeature();
    }

    async function onConferenceFailed(error) {
        try {
            await room.current.joinLobby(user.name, user.email);
        } catch (error) {
            console.info(error);
        }
    }

    function setRoomFeature() {
        if (room.current) {
            room.current.setLocalParticipantProperty("roomname", roomname);
        }
    }

    /**
     * This function is called when a new user joins.
     * @param id
     */
    function onUserJoined(id, participant) {
        if (!remoteTracks.current[id]) {
            remoteTracks.current[id] = [];

            const userRole = parseInt(participant.getProperty("role"));

            $("#remotePlace").append(`<div id="${id}Place" class="mosaicVideoContainer ${
                classes.remoteSidePlace
            } ${classes.showSelfVideo} ${classes.hiddenParticipant}">
                                        <div class="mosaicVideoContain ${
                                            isInterpreter(room.current.getParticipants(), id) ||
                                            (userRole > 0 && userRole === UserRole.LITE_USER)
                                                ? classes.hiddenParticipant
                                                : ""
                                        }"></div>
                                        <div class="displayName">${
                                            participant ? participant.getDisplayName() : ""
                                        }</div>
                                    </div>`);
        }
    }

    /**
     * This function is called when a user leaves the room.
     * @param id
     */
    function onUserLeft(id) {
        if (!remoteTracks.current[id]) {
            return;
        }
        if (!room.current.myUserId()) {
            return;
        }
        delete remoteTracks.current[id];
        $(`#${id}Place`).remove();
    }

    /**
     * Handles remote tracks
     * @param track JitsiTrack object
     */
    function onRemoteTrack(track) {
        if (track.isLocal()) {
            return;
        }
        const participants = room.current.getParticipants();
        const participantId = track.getParticipantId();

        if (!remoteTracks.current[participantId]) {
            if (!remoteTracks.current[participantId]) {
                remoteTracks.current[participantId] = [];
                const currentParticipant = _getParticipantFromTrack(track);
                const userRole = parseInt(currentParticipant.getProperty("role"));

                $("#remotePlace")
                    .append(`<div id="${participantId}Place" class="mosaicVideoContainer ${
                    classes.remoteSidePlace
                } ${classes.showSelfVideo} ${classes.hiddenParticipant}">
                                            <div class="mosaicVideoContain ${
                                                isInterpreter(
                                                    participants,
                                                    track.getParticipantId()
                                                ) ||
                                                (userRole > 0 && userRole !== UserRole.LITE_USER)
                                                    ? classes.hiddenParticipant
                                                    : ""
                                            }"></div>
                                            <div class="displayName">${
                                                currentParticipant
                                                    ? currentParticipant.getDisplayName()
                                                    : ""
                                            }</div>
                                        </div>`);
            }
        }

        remoteTracks.current[participantId].push(track);

        if (track.getType() === "video" && !isMobile()) {
            if (
                parseInt(room.current.getParticipantById(participantId).getProperty("role")) ===
                    UserRole.EMITTER &&
                user.role !== UserRole.LITE_USER
            ) {
                setReceiverConstraints(track.getParticipantId());
                track.attach($(`#bigVideo`)[0]);
            }
        } else {
            changeLangTrack(currentRoomname.current);
        }
    }

    function setReceiverConstraints(pId) {
        if (room.current) {
            const receiverConstraints = {
                defaultConstraints: { maxHeight: 720 },
                assumedBandwidthBps: 2000000,
                constraints: {}
            };

            if (pId) {
                receiverConstraints["constraints"][pId] = {
                    maxHeight: 720
                };
            }

            room.current.setReceiverConstraints(receiverConstraints);
        }
    }

    function onTrackRemoved(track) {
        console.log("🚀 ~ onTrackRemoved ~ track:", track);
        const containers = track.containers;
        if (containers.length > 0) {
            const container_id = containers[0].id;
            if (container_id) {
                if (track.disposed) {
                    track.detach($(`#${container_id}`)[0]);
                }
                if (container_id !== "bigVideo") {
                    $(`#${container_id}`).remove();
                }
            }
        }

        changeLangTrack(currentRoomname.current);
    }

    function _getParticipantFromTrack(track) {
        const currentParticipants = room.current.getParticipants();
        for (let j = 0; j < currentParticipants.length; j++) {
            if (currentParticipants[j].getId() === track.getParticipantId()) {
                return currentParticipants[j];
            }
        }
        return null;
    }

    /**
     * That function is called when connection is established successfully
     */
    function onConnectionSuccess() {
        room.current = connection.current.initJitsiConference(
            event ? event.event_name : "conference",
            { ...confOptions, statisticsId: user.email }
        );
        room.current.setSenderVideoConstraint(1080);
        room.current.setDisplayName(user.name);
        room.current.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
        room.current.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, onConferenceFailed);
        room.current.on(JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
        room.current.on(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrack);
        room.current.on(JitsiMeetJS.events.conference.TRACK_REMOVED, onTrackRemoved);
        room.current.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
        room.current.on(JitsiMeetJS.events.conference.KICKED, () => {
            console.log("kicked");
            showNotification("error", "You are kicked by admin");
        });
        room.current.addCommandListener("moderator", handleModeratorEvent);
        room.current.on(
            JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
            handleFeatureChange
        );

        room.current.on(
            JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
            (userID, displayName) => {}
        );
        room.current.on(
            JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
            (userID, audioLevel) => {}
        );
        room.current.on(JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED, () => {});

        room.current.setLocalParticipantProperty("role", user.role);

        room.current.join();
    }

    /**
     * This function is called when feature is changed.
     * @param {*} participant
     */
    function handleFeatureChange(participant, name, oldValue, newValue) {
        if (name === "role") {
            const userRole = parseInt(newValue);
            if (
                newValue === UserRole.INTERPRETER ||
                (userRole > 0 && userRole === UserRole.LITE_USER)
            ) {
                $(`#${participant.getId()}Place`).addClass(classes.hiddenParticipant);
            }

            if (parseInt(newValue) === UserRole.EMITTER) {
                const participantId = participant.getId();
                if (remoteTracks.current.hasOwnProperty(participantId)) {
                    for (let j = remoteTracks.current[participantId].length - 1; j >= 0; j--) {
                        if (
                            remoteTracks.current[participantId][j].getType() === "video" &&
                            user.role !== UserRole.LITE_USER &&
                            !isMobile()
                        ) {
                            remoteTracks.current[participantId][j].attach($(`#bigVideo`)[0]);
                            break;
                        }
                    }
                }
            }
        }

        if (name === "hand") {
            if (participant.getProperty("hand")) {
                if (!document.getElementById(`${participant.getId()}hand`)) {
                    $(`#${participant.getId()}Place`).append(
                        `<div id="${participant.getId()}hand" class="${classes.hand}">
                        <i class="fas fa-hand-paper"></i>
                        </div>`
                    );
                }
            } else {
                $(`#${participant.getId()}hand`).remove();
            }
        } else {
            changeLangTrack(currentRoomname.current);
        }
    }

    function handleModeratorEvent(results) {
        const value = results.value;
        if (value === room.current.myUserId()) {
            if (results.attributes.actionType === "notify") {
                const content = results.attributes.content;
                console.log(content);
            }
        }
    }

    /**
     * This function is called when the connection fail.
     */
    function onConnectionFailed(errType, errReason, credentials, errReasonDetails) {}

    /**
     * This function is called when the connection fail.
     */
    function onDeviceListChanged(devices) {}

    /**
     * This function is called when we disconnect.
     */
    function disconnect(msg) {
        connection.current.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            onConnectionSuccess
        );
        connection.current.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            onConnectionFailed
        );
        connection.current.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            disconnect
        );
    }

    async function unload() {
        if (room.current && room.current.room) {
            const currentTracks = room.current.getLocalTracks();
            for (let i = 0; i < currentTracks.length; i++) {
                if (!currentTracks[i].disposed) {
                    try {
                        await currentTracks[i].dispose();
                    } catch (error) {
                        console.info(error);
                    }
                }
            }
            try {
                await room.current.leave();
            } catch (error) {
                console.info(error);
            }
            await connection.current.disconnect();
            postStat(2);
        }
        return;
    }

    function postStat(status) {
        const currentRoom = event.event_rooms.filter(
            event_room => event_room.room.room_name === currentRoomname.current
        );
        let stat = {
            event_id: event.id,
            status: status,
            room_id: currentRoom.length > 0 ? currentRoom[0].room_id : 0,
            start_stat_id: startStatId ? startStatId : 0
        };
        addStat(stat);
    }

    // TODO:
    function handleHoverEvent(event) {}

    // TODO:
    function handleLeaveEvent(event) {}

    /**
     * Handle AudioOutputSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioOutputSettingChange(value, isOpen = true) {
        const selectAudioOutput = value;
        if (isOpen) {
            setAudioOutputSetting(selectAudioOutput);
            JitsiMeetJS.mediaDevices.setAudioOutputDevice(selectAudioOutput);
        }
        setOpenAudioOutputSettingDlg(isOpen);
    }

    return (
        <>
            {user.role === UserRole.LITE_USER && event.event_type === EventType.WEB ? (
                <MeetContainer className="row">
                    <div className="col-md-12">
                        <div
                            id="localPlace"
                            // className={classes.localPlace}
                        >
                            <Iframe
                                url={`${event.extra_url}?autoplay=1&mute=1`}
                                width="100%"
                                height="100%"
                                id="myId"
                                frameBorder={0}
                                className={classes.extraVideo}
                                display="initial"
                                position="relative"
                                allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            />
                        </div>
                    </div>
                </MeetContainer>
            ) : (
                <MeetContainer className="row">
                    <div className="col-md-12">
                        <div
                            id="localPlace"
                            className={classes.localPlace}
                            onMouseEnter={handleHoverEvent}
                            onMouseLeave={handleLeaveEvent}
                        >
                            <div className={classes.avatar}>
                                <img
                                    src={
                                        event.pic
                                            ? process.env.REACT_APP_FILE_URL + event.pic
                                            : toAbsoluteUrl("/media/logos/logo-trans.png")
                                    }
                                    alt="avatar"
                                />
                            </div>

                            <ToolBar
                                event={event}
                                disableAudioInput={true}
                                disableVideoInput={true}
                                disableShare={true}
                                disableChat={true}
                                disableRaiseHand={true}
                            />

                            {user.role !== UserRole.LITE_USER && (
                                <video
                                    muted
                                    autoPlay="1"
                                    id="bigVideo"
                                    className={`${classes.bigVideoFullWidth} ${classes.bigVideo}`}
                                />
                            )}

                            <div id="remotePlace"></div>

                            {isSubTitle && (
                                <div id="subTitleWrapper" className={classes.subTitleWrapper}>
                                    <div id="subTitle" className={classes.subTitle}>
                                        {event.subtitle && event.subtitle !== "" && (
                                            <iframe
                                                title={event.title}
                                                src={event.subtitle}
                                                className={classes.subTitleIframe}
                                            ></iframe>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Audio Output Setting Dialog */}
                            <SelectDeviceDialog
                                id="audioOutputSetting"
                                title={intl.formatMessage({
                                    id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                                })}
                                label={intl.formatMessage({
                                    id: "VIDEO.SETTING.AUDIO_OUPUTS"
                                })}
                                isOpen={openAudioOutputSettingDlg}
                                devices={audioOutputDevices}
                                currentValue={audioOutputSetting}
                                onChange={handleAudioOutputSettingChange}
                            />
                        </div>
                    </div>
                </MeetContainer>
            )}
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        authToken: state.auth.authToken,
        isEndMeeting: state.event.isEndMeeting,
        startStatId: state.event.startStatId,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg,
        isSubTitle: state.event.isSubTitle
    };
};

const mapDispatchToProps = dispatch => ({
    endMeetingSuccess: () => dispatch(eventStore.actions.endMeetingSuccess()),
    addStat: data => dispatch(eventStore.actions.addStat(data)),
    showNotification: (type, content) =>
        dispatch(eventStore.actions.showNotification(type, content)),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConferenceVideo));
