import React, { useState } from "react";
import { makeStyles, Popper, Fade } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import { colors } from "@material-ui/core";
import AudioDetector from "./AudioDetector";

const useStyles = makeStyles(() => ({
    videoToolbox: {
        position: "absolute",
        bottom: 3,
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    toolboxMenuContainer: {
        background: "gray",
        fontSize: "0.8rem",
        listStyle: "none",
        margin: 0,
        marginRight: 2,
        padding: 0,
        borderRadius: 5,
        color: colors.common.white,
        zIndex: 3,
        "& li": {
            padding: 5,
            cursor: "pointer",
            wordBreak: "break-all",
            textAlign: "center"
        },
        "& li:hover": {
            background: "rgba(180, 180, 180, 0.5)"
        }
    }
}));

function RemoteVideo(props) {
    const {
        id,
        isMicOn,
        isMuted,
        displayName,
        allowUser,
        muteParticipant,
        kickParticipant,
        openChatBox,
        setToParticipant,
        messageList,
        lang,
        sendPreMsg,
        isNotify,
        audioLevels,
        isMosaic,
        isHand,
        output_lang,
        children
    } = props;
    const [anchorElMore, setAnchorElMore] = React.useState(null);
    const [anchorElNotify, setAnchorElNotify] = React.useState(null);
    const [isHoverMore, setIsHoverMore] = useState(false);
    const [isHoverNotifyList, setIsHoverNotifyList] = useState(false);

    const [isAllow, setIsAllow] = React.useState(false);
    const classes = useStyles();

    const _handleHoverMoreBtn = (event, value) => {
        setAnchorElMore(event.currentTarget);
        setIsHoverMore(value);
    };

    function _handleHoverNotify(event, value) {
        setAnchorElNotify(event.currentTarget);
        setIsHoverNotifyList(value);
    }
    function _handleAllowUser() {
        setIsHoverMore(false);
        setIsAllow(!isAllow);
        allowUser(id);
    }

    function _handleMuteUser() {
        setIsHoverMore(false);
        muteParticipant(id);
    }

    function _handleKickUser() {
        setIsHoverMore(false);
        kickParticipant(id);
    }

    function _handlePrivateMessage() {
        setIsHoverMore(false);
        openChatBox(true);
        setToParticipant(id);
    }

    function _handleSelectPreMsg(e, i) {
        e.preventDefault();
        const msg = messageList[i];
        delete msg.id;
        delete msg.is_enabled;
        sendPreMsg(id, messageList[i]);
        // Close menus
        setIsHoverMore(false);
        setIsHoverNotifyList(false);
    }

    return (
        <>
            <div className={`${props.baseClasses.videoContainer} videoContainer`}>{children}</div>
            <div className="displayName">{displayName}</div>
            <AudioDetector pId={id} audioLevels={audioLevels} />
            <div
                className={classes.videoToolbox}
                onMouseEnter={e => _handleHoverMoreBtn(e, true)}
                onMouseLeave={e => _handleHoverMoreBtn(e, false)}
            >
                <MoreVertIcon />
                <Popper
                    open={isHoverMore}
                    anchorEl={anchorElMore}
                    placement={isMosaic ? "top-start" : "left-end"}
                    transition
                    disablePortal={true}
                    modifiers={{
                        flip: {
                            enabled: true
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "viewport"
                        }
                    }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={500}>
                            <div>
                                <ul className={classes.toolboxMenuContainer}>
                                    {typeof allowUser === "function" && (
                                        <li onClick={_handleAllowUser}>
                                            {isAllow ? "Disallow" : "Allow"}
                                        </li>
                                    )}
                                    {typeof muteParticipant === "function" && (
                                        <li onClick={_handleMuteUser}>Mute User</li>
                                    )}
                                    {typeof kickParticipant === "function" && (
                                        <li onClick={_handleKickUser}>Kick User</li>
                                    )}
                                    <li onClick={_handlePrivateMessage}>Send Message</li>
                                    {isNotify && (
                                        <li
                                            onMouseEnter={e => _handleHoverNotify(e, true)}
                                            onMouseLeave={e => _handleHoverNotify(e, false)}
                                        >
                                            Notify
                                            <Popper
                                                open={isHoverNotifyList}
                                                anchorEl={anchorElNotify}
                                                placement={isMosaic ? "right-end" : "left-end"}
                                                transition
                                                disablePortal={true}
                                            >
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={500}>
                                                        {messageList && messageList.length > 0 && (
                                                            <ul
                                                                className={
                                                                    classes.toolboxMenuContainer
                                                                }
                                                            >
                                                                {messageList.map((message, i) => (
                                                                    <li
                                                                        key={i}
                                                                        onClick={e =>
                                                                            _handleSelectPreMsg(
                                                                                e,
                                                                                i
                                                                            )
                                                                        }
                                                                    >
                                                                        {message[`msg_${lang}`] ??
                                                                            message["msg_en"]}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </Fade>
                                                )}
                                            </Popper>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </Fade>
                    )}
                </Popper>
                <div className="">
                    {output_lang ? (
                        <span style={{ color: colors.yellow[500] }}>
                            {output_lang.lang_name.toUpperCase()}
                        </span>
                    ) : (
                        ""
                    )}
                    {!isMuted && isMicOn ? (
                        <VolumeUpIcon style={{ color: colors.grey[500] }} />
                    ) : (
                        <VolumeOffIcon style={{ color: colors.green[500] }} />
                    )}
                    {isMicOn ? (
                        <MicIcon style={{ color: colors.red[500] }} />
                    ) : (
                        <MicOffIcon style={{ color: colors.grey[500] }} />
                    )}
                </div>
                <div></div>
            </div>

            {isHand && (
                <div id={`${id}hand`} className={props.baseClasses.hand}>
                    <i className="fas fa-hand-paper"></i>
                </div>
            )}
        </>
    );
}

export default RemoteVideo;
