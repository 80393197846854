import React, { useState } from "react";
import { makeStyles, colors } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideoTrack from "../../../../components/VideoTrack";
import { jitsiMeeting } from "../../../../components/JitsiMeeting";

const useStyles = makeStyles(theme => ({
    videoToolbox: {
        position: "absolute",
        bottom: 3,
        color: "#646c9a",
        zIndex: 1
    },
    audioToolbox: {
        position: "absolute",
        bottom: 3,
        color: "#646c9a",
        width: "100%",
        textAlign: "center"
    },
    toolboxMenuContainer: {
        position: "absolute",
        bottom: 0,
        right: "80%",
        background: "gray",
        listStyle: "none",
        margin: 0,
        // marginRight: 5,
        padding: 0,
        borderRadius: 5,
        color: colors.common.white,
        "& li": {
            whiteSpace: "nowrap",
            padding: 5
        },
        "& li:hover": {
            background: "rgba(180, 180, 180, 0.5)"
        }
    },
    notifyListMenuContainer: {
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: 140
    },
    popupMenuLeft: {
        position: "absolute",
        height: "100%",
        width: 50,
        top: 0,
        bottom: 0,
        left: -30
    },
    popupMenuRight: {
        position: "absolute",
        height: "100%",
        width: 50,
        top: 0,
        bottom: 0,
        right: -30
    },
    actionMenu: {
        color: colors.common.white
    }
}));

const messageList = [
    // { msg_en: "Ready for handover" },
    // { msg_en: "Handover now" },
    { msg_en: "Wrong OUT CH" },
    { msg_en: "Check MUTE" },
    { msg_en: "Check MIC" }
];

function GroupTranslatorRemoteVideo(props) {
    const { id, displayName, openChatBox, setToParticipant, lang, participant, event } = props;
    const [isHoverMore, setIsHoverMore] = useState(false);
    const [isHoverNotifyList, setIsHoverNotifyList] = useState(false);
    const classes = useStyles();

    function _handleHoverMoreBtn(value) {
        setIsHoverMore(value);
    }

    function _handleHoverNotify(val) {
        setIsHoverNotifyList(val);
    }

    function _handlePrivateMessage() {
        setIsHoverMore(false);
        openChatBox(true);
        setToParticipant(id);
    }

    function _handleSelectPreMsg(e, i) {
        e.preventDefault();
        console.log(messageList[i]);
        sendPreMsg(id, messageList[i]);
    }

    function sendPreMsg(participantId, msg) {
        console.log(participantId, msg);
        console.log({
            actionType: "notify",
            content: "msg",
            ...msg
        });
        jitsiMeeting.room.sendCommandOnce("moderator", {
            value: participantId,
            attributes: {
                actionType: "notify",
                content: "msg",
                ...msg
            }
        });
    }

    const audioTrack = jitsiMeeting.remoteTracks[id]
        ? jitsiMeeting.remoteTracks[id].find(t => t.getType() === "audio" && !t.disposed)
        : null;

    const isMuted = audioTrack && audioTrack.isMuted();
    const pOutput = participant.getProperty("output");
    const isMicOn = pOutput ? !pOutput.includes("non") : false;
    let output_lang = null;

    if (!isMicOn) {
        output_lang = "ORI";
    } else {
        const p_output_room = event.event_rooms.find(
            event_room => event_room.room_name === pOutput
        );

        output_lang = p_output_room ? p_output_room.output_lang_short : null;
    }
    console.log(output_lang);

    return (
        <>
            <div className={`${props.baseClasses.videoContainer} videoContainer`}>
                <VideoTrack
                    muted
                    autoPlay="1"
                    id={`${id}GroupTranslatorVideo`}
                    className={props.baseClasses.videoElement}
                    videoTrack={{
                        jitsiTrack: jitsiMeeting.remoteTracks[id]
                            ? jitsiMeeting.remoteTracks[id].find(
                                  t => t.getType() === "video" && !t.disposed
                              )
                            : null
                    }}
                />
            </div>
            <div className="displayName">{displayName}</div>
            <div
                className={classes.videoToolbox}
                onMouseEnter={() => _handleHoverMoreBtn(true)}
                onMouseLeave={() => _handleHoverMoreBtn(false)}
            >
                <MoreVertIcon className={classes.actionMenu} />
                {isHoverMore && (
                    <ul className={classes.toolboxMenuContainer}>
                        <li onClick={_handlePrivateMessage}>Send Message</li>
                        {messageList.map((message, i) => (
                            <li key={i} onClick={e => _handleSelectPreMsg(e, i)}>
                                {message["msg_en"]}
                            </li>
                        ))}
                        {/* {isNotify && */}
                        {/* <li onMouseEnter={() => _handleHoverNotify(true)} onMouseLeave={() => _handleHoverNotify(false)}>
                            Notify <div className={classes.popupMenuLeft}></div>{
                                isHoverNotifyList &&
                                // messageList && messageList.length > 0 &&
                                <ul className={`${classes.toolboxMenuContainer} ${classes.notifyListMenuContainer}`}>
                                    {
                                        messageList.map((message, i) => (
                                            <li key={i} onClick={(e) => _handleSelectPreMsg(e, i)}>
                                                {message}
                                            </li>
                                        ))
                                    }
                                    <div className={classes.popupMenuRight}></div>
                                </ul>
                            }
                        </li> */}
                        {/* } */}
                    </ul>
                )}
            </div>
            <div className={classes.audioToolbox}>
                {output_lang ? (
                    <span style={{ color: colors.yellow[500] }}>{output_lang.toUpperCase()}</span>
                ) : (
                    ""
                )}
                {!isMuted && isMicOn ? (
                    <VolumeUpIcon style={{ color: colors.grey[500] }} />
                ) : (
                    <VolumeOffIcon style={{ color: colors.green[500] }} />
                )}
                {isMicOn ? (
                    <MicIcon style={{ color: colors.red[500] }} />
                ) : (
                    <MicOffIcon style={{ color: colors.grey[500] }} />
                )}
            </div>
        </>
    );
}

export default GroupTranslatorRemoteVideo;
