/* eslint-disable lines-around-comment */
import { timeout } from "./functions";

import JitsiStreamBackgroundEffect from "./JitsiStreamBackgroundEffect";
import createTFLiteModule from "./vendor/tflite/tflite";
import createTFLiteSIMDModule from "./vendor/tflite/tflite-simd";
console.log(window.location.origin);
const models = {
    modelLandscape: `${window.location.origin}/libs/selfie_segmentation_landscape.tflite`
};
/* eslint-enable lines-around-comment */

let modelBuffer;
let tflite;
let wasmCheck;
let isWasmDisabled = false;

const segmentationDimensions = {
    modelLandscape: {
        height: 144,
        width: 256
    }
};

/**
 * Creates a new instance of JitsiStreamBackgroundEffect. This loads the Meet background model that is used to
 * extract person segmentation.
 *
 * @param {Object} virtualBackground - The virtual object that contains the background image source and
 * the isVirtualBackground flag that indicates if virtual image is activated.
 * @returns {Promise<JitsiStreamBackgroundEffect>}
 */
export async function createVirtualBackgroundEffect(virtualBackground) {
    if (!MediaStreamTrack.prototype.getSettings && !MediaStreamTrack.prototype.getConstraints) {
        throw new Error("JitsiStreamBackgroundEffect not supported!");
    }

    if (isWasmDisabled) {
        console.log("🚀 ~ virtualBackground.backgroundEffectError");

        return;
    }

    // Checks if WebAssembly feature is supported or enabled by/in the browser.
    // Conditional import of wasm-check package is done to prevent
    // the browser from crashing when the user opens the app.

    if (!tflite) {
        try {
            wasmCheck = require("wasm-check");
            const tfliteTimeout = 10000;

            if (wasmCheck?.feature?.simd) {
                tflite = await timeout(tfliteTimeout, createTFLiteSIMDModule());
            } else {
                tflite = await timeout(tfliteTimeout, createTFLiteModule());
            }
        } catch (err) {
            if (err?.message === "408") {
                console.error(
                    "🚀 ~ virtualBackground.backgroundEffectError: Failed to download tflite model!",
                    err
                );
            } else {
                isWasmDisabled = true;

                console.error(
                    "🚀 ~ virtualBackground.webAssemblyWarningDescription: Looks like WebAssembly is disabled or not supported on this browser",
                    err
                );
            }

            return;
        }
    }

    if (!modelBuffer) {
        const modelResponse = await fetch(models.modelLandscape);

        if (!modelResponse.ok) {
            throw new Error("Failed to download tflite model!");
        }

        modelBuffer = await modelResponse.arrayBuffer();

        tflite.HEAPU8.set(new Uint8Array(modelBuffer), tflite._getModelBufferMemoryOffset());

        tflite._loadModel(modelBuffer.byteLength);
    }

    const options = {
        ...segmentationDimensions.modelLandscape,
        virtualBackground
    };

    return new JitsiStreamBackgroundEffect(tflite, options);
}
