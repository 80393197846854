import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { register } from "../../crud/auth.crud";

function Registration(props) {
    const { intl } = props;

    return (
        <div className="kt-login__body">
            <div className="kt-login__form">
                <div className="kt-login__title">
                    <h3>
                        <FormattedMessage id="AUTH.REGISTER.TITLE" />
                    </h3>
                </div>

                <Formik
                    initialValues={{
                        email: "",
                        name: "",
                        acceptTerms: true
                    }}
                    validate={values => {
                        const errors = {};

                        if (!values.email) {
                            errors.email = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_FIELD"
                            });
                        }

                        if (!values.name) {
                            errors.name = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.acceptTerms) {
                            errors.acceptTerms = "Accept Terms";
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        register(values.email, values.name)
                            .then(({ data: { accessToken } }) => {
                                // props.register(accessToken);
                                // props.history.push('auth/login')
                                setStatus(
                                    intl.formatMessage({
                                        id: "AUTH.REGISTER.SUCCESS.PASSWORD_SENT"
                                    })
                                );
                            })
                            .catch(error => {
                                const status = error.response.status;
                                if (status === 422) {
                                    setStatus(
                                        intl.formatMessage({
                                            id: "AUTH.VALIDATION.EMAIL_EXISTED"
                                        })
                                    );
                                } else {
                                    setStatus(
                                        intl.formatMessage({
                                            id: "AUTH.VALIDATION.REGISTER.ERROR"
                                        })
                                    );
                                }
                                setSubmitting(false);
                            });
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            {status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}

                            <div className="form-group mb-0">
                                <TextField
                                    margin="normal"
                                    label="Fullname"
                                    className="kt-width-full"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    helperText={touched.name && errors.name}
                                    error={Boolean(touched.name && errors.name)}
                                />
                            </div>

                            <div className="form-group mb-0">
                                <TextField
                                    label="Email"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(touched.email && errors.email)}
                                />
                            </div>

                            {/* <div className="form-group mb-0">
                  <TextField
                    margin="normal"
                    label="Username"
                    className="kt-width-full"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    helperText={touched.username && errors.username}
                    error={Boolean(touched.username && errors.username)}
                  />
                </div> */}

                            {/* <div className="form-group mb-0">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Confirm Password"
                    className="kt-width-full"
                    name="password_confirmation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password_confirmation}
                    helperText={touched.password_confirmation && errors.password_confirmation}
                    error={Boolean(
                      touched.password_confirmation && errors.password_confirmation
                    )}
                  />
                </div> */}

                            <div className="form-group mb-0">
                                <FormControlLabel
                                    label={
                                        <>
                                            I agree the{" "}
                                            <Link
                                                to="/terms"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Terms & Conditions
                                            </Link>
                                        </>
                                    }
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name="acceptTerms"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            checked={values.acceptTerms}
                                        />
                                    }
                                />
                            </div>

                            <div className="kt-login__actions">
                                <Link to="/auth">
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                                    >
                                        Back
                                    </button>
                                </Link>

                                <button
                                    disabled={isSubmitting || !values.acceptTerms}
                                    className="btn btn-primary btn-elevate kt-login__btn-primary"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Registration));
