import { put, takeEvery } from "redux-saga/effects";

import {
    get_customers,
    update_customer,
    add_customer,
    remove_customer,
    update_customer_password
} from "../../crud/customer.crud";
import { active_user } from "../../crud/admin.crud";
import { UserRole } from "../../components/utils/UserRole";
export const actionTypes = {
    GET_CUSTOMERS: "GET_CUSTOMERS",
    RENDER_CUSTOMER_LIST: "RENDER_CUSTOMER_LIST",
    UPDATE_CUSTOMERS: "UPDATE_CUSTOMERS",
    UPDATE_CUSTOMERS_SUCCESS: "UPDATE_CUSTOMERS_SUCCESS",
    ADD_CUSTOMER: "ADD_CUSTOMER",
    ADD_CUSTOMER_SUCCESS: "ADD_CUSTOMER_SUCCESS",
    REMOVE_CUSTOMER: "REMOVE_CUSTOMER",
    REMOVE_CUSTOMER_SUCCESS: "REMOVE_CUSTOMER_SUCCESS",
    ACTIVE_CUSTOMER: "ACTIVE_CUSTOMER",
    ACTIVE_CUSTOMER_SUCCESS: "ACTIVE_CUSTOMER_SUCCESS",
    UPDATE_CUSTOMER_PASSWORD: "UPDATE_CUSTOMER_PASSWORD",
    UPDATE_CUSTOMER_PASSWORD_SUCCESS: "UPDATE_CUSTOMER_PASSWORD_SUCCESS"
};

export const actions = {
    getCustomers: () => ({ type: actionTypes.GET_CUSTOMERS }),
    renderCustomerList: customerList => ({
        type: actionTypes.RENDER_CUSTOMER_LIST,
        customerList: customerList
    }),
    updateCustomers: customer => ({
        type: actionTypes.UPDATE_CUSTOMERS,
        newCustomer: customer
    }),
    updateCustomersSuccess: () => ({
        type: actionTypes.UPDATE_CUSTOMERS_SUCCESS
    }),
    addCustomer: customer => ({
        type: actionTypes.ADD_CUSTOMER,
        newCustomer: customer
    }),
    addCustomerSuccess: customer => ({
        type: actionTypes.ADD_CUSTOMER_SUCCESS,
        newCustomer: customer
    }),
    removeCustomer: customerId => ({
        type: actionTypes.REMOVE_CUSTOMER,
        customerId: customerId
    }),
    removeCustomerSuccess: customerId => ({
        type: actionTypes.REMOVE_CUSTOMER_SUCCESS,
        customerId: customerId
    }),
    activeCustomer: customerId => ({
        type: actionTypes.ACTIVE_CUSTOMER,
        customerId: customerId
    }),
    activeCustomerSuccess: (customerId, userStatus) => ({
        type: actionTypes.ACTIVE_CUSTOMER_SUCCESS,
        customerId: customerId,
        userStatus: userStatus
    }),
    updateCustomerPassword: (customerId, password) => ({
        type: actionTypes.UPDATE_CUSTOMER_PASSWORD,
        customerId: customerId,
        password: password
    }),
    updateCustomerPasswordSuccess: () => ({
        type: actionTypes.UPDATE_CUSTOMER_PASSWORD_SUCCESS
    })
};

const initialCustomersState = {
    customerList: []
};

export const reducer = (state = initialCustomersState, action) => {
    switch (action.type) {
        case actionTypes.RENDER_CUSTOMER_LIST: {
            return {
                ...state,
                customerList: action.customerList
            };
        }
        case actionTypes.ADD_CUSTOMER_SUCCESS: {
            let newCustomerList = [
                ...state.customerList,
                {
                    ...action.newCustomer
                }
            ];
            return {
                ...state,
                customerList: newCustomerList
            };
        }
        case actionTypes.REMOVE_CUSTOMER_SUCCESS: {
            let oldCustomerList = [...state.customerList];
            const newCustomerList = oldCustomerList.filter(
                customer => customer.id !== action.customerId
            );
            return {
                ...state,
                customerList: newCustomerList
            };
        }
        case actionTypes.ACTIVE_CUSTOMER_SUCCESS: {
            let oldCustomerList = [...state.customerList];
            const newCustomerList = oldCustomerList.map(customer => {
                if (customer.id === action.customerId) {
                    customer.is_active = action.userStatus;
                }
                return customer;
            });
            return {
                ...state,
                customerList: newCustomerList
            };
        }
        case actionTypes.UPDATE_CUSTOMER_PASSWORD_SUCCESS: {
            return {
                ...state,
                message: { status: "success", content: "Password is reset Successfully" }
            };
        }
        default:
            return state;
    }
};

export function* customersSaga() {
    yield takeEvery(actionTypes.GET_CUSTOMERS, function* getCustomersSaga() {
        try {
            const response = yield get_customers();
            const data = yield response.data ?? [];
            yield put(actions.renderCustomerList(data.customers));
        } catch {
            yield put(actions.renderCustomerList([]));
        }
    });

    yield takeEvery(actionTypes.UPDATE_CUSTOMERS, function* updateCustomersSaga(action) {
        const newCustomer = action.newCustomer;
        if (action.newCustomer.isLight)
            newCustomer.role = action.newCustomer.isLight ? UserRole.LITE_USER : UserRole.USER;
        yield update_customer(newCustomer.id, newCustomer);
        yield put(actions.updateCustomersSuccess());
    });

    yield takeEvery(actionTypes.ADD_CUSTOMER, function* addCustomerSaga(action) {
        const newCustomer = action.newCustomer;
        const response = yield add_customer(newCustomer);
        yield put(actions.addCustomerSuccess(response.data.data));
    });

    yield takeEvery(actionTypes.REMOVE_CUSTOMER, function* removeCustomerSaga(action) {
        const customerId = action.customerId;
        yield remove_customer(customerId);
        yield put(actions.removeCustomerSuccess(customerId));
    });
    yield takeEvery(actionTypes.ACTIVE_CUSTOMER, function* activeCustomerSaga(action) {
        const id = action.customerId;
        const response = yield active_user(id);
        yield put(actions.activeCustomerSuccess(id, response.data.user_status));
    });
    yield takeEvery(actionTypes.UPDATE_CUSTOMER_PASSWORD, function* updateCustomerPasswordSaga(
        action
    ) {
        const customerId = action.customerId;
        const password = action.password;
        const response = yield update_customer_password(customerId, password);
        if (response.data.status === "success") {
            yield put(actions.updateCustomerPasswordSuccess("success"));
        }
    });
}
