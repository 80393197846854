export const VIRTUAL_BACKGROUND_TYPE = {
    IMAGE: "image",
    BLUR: "blur",
    NONE: "none"
};

export const VIRTUAL_BACKGROUNDS = [
    { value: "none", label: "None", bgColor: "rgb(82, 82, 82)" },
    { value: "half-blur", label: "Half Blur", bgColor: "rgb(164, 164, 164)" },
    { value: "blur", label: "Blur", bgColor: "rgb(126, 130, 135)" },
    {
        value: "background-1",
        label: "Background 1",
        image: "/media/bg/background-1.jpg"
    },
    {
        value: "background-2",
        label: "Background 2",
        image: "/media/bg/background-2.jpg"
    },
    {
        value: "background-3",
        label: "Background 3",
        image: "/media/bg/background-3.jpg"
    },
    {
        value: "background-4",
        label: "Background 4",
        image: "/media/bg/background-4.jpg"
    },
    {
        value: "background-5",
        label: "Background 5",
        image: "/media/bg/background-5.jpg"
    },
    {
        value: "background-6",
        label: "Background 6",
        image: "/media/bg/background-6.jpg"
    },
    {
        value: "background-7",
        label: "Background 7",
        image: "/media/bg/background-7.jpg"
    }
];
