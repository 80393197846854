import { put, takeEvery } from "redux-saga/effects";
import {
    get_customer_events,
    get_customer_rooms,
    get_customer_event,
    get_lite_customer_event
} from "../../../crud/customers/index.crud";

export const actionTypes = {
    GET_CUSTOMER_EVENT: "GET_CUSTOMER_EVENT",
    GET_CUSTOMER_EVENT_SUCCESS: "GET_CUSTOMER_EVENT_SUCCESS",
    GET_CUSTOMER_EVENTS: "GET_CUSTOMER_EVENTS",
    GET_CUSTOMER_EVENTS_SUCCESS: "GET_CUSTOMER_EVENTS_SUCCESS",
    GET_CUSTOMER_ROOMS: "GET_CUSTOMER_ROOMS",
    GET_CUSTOMER_ROOMS_SUCCESS: "GET_CUSTOMER_ROOMS_SUCCESS",
    GET_LITE_CUSTOMER_EVENT: "GET_LITE_CUSTOMER_EVENT"
};

export const actions = {
    getCustomerEvent: id => ({
        type: actionTypes.GET_CUSTOMER_EVENT,
        event_id: id
    }),
    getCustomerEventSuccess: event => ({
        type: actionTypes.GET_CUSTOMER_EVENT_SUCCESS,
        event: event
    }),
    getCustomerEvents: () => ({ type: actionTypes.GET_CUSTOMER_EVENTS }),
    getCustomerEventsSuccess: eventList => ({
        type: actionTypes.GET_CUSTOMER_EVENTS_SUCCESS,
        eventList: eventList
    }),
    getCustomerRooms: () => ({ type: actionTypes.GET_CUSTOMER_ROOMS }),
    getCustomerRoomsSuccess: roomList => ({
        type: actionTypes.GET_CUSTOMER_ROOMS_SUCCESS,
        roomList: roomList
    }),
    getLiteCustomerEvent: (event_name, user) => ({
        type: actionTypes.GET_LITE_CUSTOMER_EVENT,
        event_name: event_name,
        user: user
    })
};

const initialCustomersState = {
    isEvent: false,
    eventList: [],
    roomList: []
};

export const reducer = (state = initialCustomersState, action) => {
    switch (action.type) {
        case actionTypes.GET_CUSTOMER_EVENT: {
            return {
                ...state,
                isEvent: false
            };
        }
        case actionTypes.GET_LITE_CUSTOMER_EVENT: {
            return {
                ...state,
                isEvent: false
            };
        }
        case actionTypes.GET_CUSTOMER_EVENT_SUCCESS: {
            return {
                ...state,
                event: action.event,
                isEvent: true
            };
        }
        case actionTypes.GET_CUSTOMER_EVENTS_SUCCESS: {
            return {
                ...state,
                eventList: action.eventList
            };
        }
        case actionTypes.GET_CUSTOMER_ROOMS_SUCCESS: {
            return {
                ...state,
                roomList: action.roomList
            };
        }
        default:
            return state;
    }
};

export function* customersModuleSaga() {
    yield takeEvery(actionTypes.GET_CUSTOMER_EVENT, function* getEventSaga(action) {
        const response = yield get_customer_event(action.event_id);
        if (response) {
            const data = response.data;
            if (data) {
                yield put(actions.getCustomerEventSuccess(data.data));
            }
        }
    });

    yield takeEvery(actionTypes.GET_CUSTOMER_EVENTS, function* getEventsSaga() {
        try {
            const response = yield get_customer_events();
            const data = yield response.data ?? [];
            if (data) {
                yield put(actions.getCustomerEventsSuccess(data.data));
            }
        } catch {
            yield put(actions.getCustomerEventsSuccess([]));
        }
    });

    yield takeEvery(actionTypes.GET_CUSTOMER_ROOMS, function* getRoomsSaga() {
        try {
            const response = yield get_customer_rooms();
            const data = yield response.data ?? [];
            if (data) {
                yield put(actions.getCustomerRoomsSuccess(data.data));
            }
        } catch {
            yield put(actions.getCustomerRoomsSuccess([]));
        }
    });

    yield takeEvery(actionTypes.GET_LITE_CUSTOMER_EVENT, function* getLiteEventSaga(action) {
        try {
            const response = yield get_lite_customer_event(action.event_name, action.user);
            if (response) {
                const data = response.data;
                if (data) {
                    yield put(actions.getCustomerEventSuccess(data.data));
                }
            } else {
                yield put(actions.getCustomerEventSuccess(null));
            }
        } catch {
            yield put(actions.getCustomerEventSuccess(null));
        }
    });
}
