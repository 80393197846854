import React, { useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
    makeStyles,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    TablePagination,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PhoneIcon from "@material-ui/icons/Phone";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ShareIcon from "@material-ui/icons/Share";
import { toAbsoluteUrl } from "../../../../../_metronic";
import Container from "../../../../partials/content/Container";
import {
    EnhancedTableToolbar,
    EnhancedTableHead,
    stableSort,
    getSorting,
    searchArray
} from "../../../../components/EnhancedTable";
import * as eventsStore from "../../../../store/ducks/events.duck";
import * as languagesStore from "../../../../store/ducks/languages.duck";
import * as emittersStore from "../../../../store/ducks/emitters.duck";
import * as adminsStore from "../../../../store/ducks/admins.duck";
import * as managersStore from "../../../../store/ducks/managers.duck";
import * as transStore from "../../../../store/ducks/trans.duck";
import EventDlg from "./EventDlg";
import EventParticipantsDlg from "./EventParticipantsDlg";
import EventShareDlg from "./EventShareDlg";
import { UserRole } from "../../../../components/utils/UserRole";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        // minWidth: 750
    },
    tableWrapper: {
        overflowX: "auto"
    }
}));

function EventList(props) {
    const {
        user,
        eventList,
        admins,
        managers,
        emitters,
        trans,
        languages,
        getEvents,
        getAdmins,
        getManagers,
        getEmitters,
        getTrans,
        getLanguages,
        removeEvent,
        intl
    } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchKey, setSearchKey] = React.useState("");

    // Table Keys
    const [openFormDlg, setOpenFormDlg] = React.useState(false);
    const [openDel, setOpenDel] = React.useState(false);
    const [openParticipantsDlg, setOpenParticipantsDlg] = React.useState(false);
    const [openShareDlg, setOpenShareDlg] = React.useState(false);
    const [clickedValue, setClickedValue] = React.useState(-1);
    const [clickedEventValue, setClickedEventValue] = React.useState({});

    useEffect(() => {
        getEvents();
        getEmitters();
        if (user.role === UserRole.SUPERADMIN) {
            getAdmins();
        }
        getManagers();
        getTrans();
        getLanguages();
    }, [getEvents, getEmitters, getAdmins, getTrans, getLanguages]);

    const headRows = [
        {
            id: "title",
            numeric: false,
            disablePadding: false,
            label: intl.formatMessage({ id: "EVENTS.TABLE_HEADER.TITLE" })
        },
        {
            id: "detail",
            numeric: false,
            disablePadding: false,
            className: "d-none d-sm-table-cell p-0",
            label: intl.formatMessage({ id: "EVENTS.TABLE_HEADER.DETAIL" })
        },
        {
            id: "start_datetime",
            numeric: false,
            disablePadding: false,
            className: "d-none d-sm-table-cell p-0",
            label: intl.formatMessage({ id: "EVENTS.TABLE_HEADER.START_DATE" })
        },
        {
            id: "emitter_id",
            numeric: false,
            disablePadding: false,
            className: "d-none d-sm-table-cell p-0",
            label: intl.formatMessage({ id: "EVENTS.TABLE_HEADER.EMITTER" })
        }
    ];

    const searchable = ["title", "detail", "start_datetime", "emitter_name"];

    // Handle Tables Events
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, eventList.length - page * rowsPerPage);

    const isSelected = id => selected.indexOf(id) !== -1;

    function handleCheckClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = eventList.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    function handleAdd() {
        setOpenFormDlg(true);
    }

    function handleEdit(id) {
        const selectedEvent = _.find(eventList, ["id", id]);
        setClickedEventValue(selectedEvent);
        setClickedValue(id);
        setOpenFormDlg(true);
    }

    function handleJoin(event, id) {
        props.history.push(`/events/${id}`);
    }

    function handleDeleteClick(event, id) {
        setClickedValue(id);
        setOpenDel(true);
    }

    function handleCloseDel(value) {
        setClickedValue(-1);
        setOpenDel(false);
    }

    function handleClose(value) {
        setClickedEventValue({});
        setClickedValue(-1);
        setOpenFormDlg(false);
    }

    function handleDelete(event) {
        removeEvent(clickedValue);
        setOpenDel(false);
    }

    function convertApiToLocalTime(start_datetime) {
        if (start_datetime) {
            const time = new Date(start_datetime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            });
            return start_datetime.split(/[\sT]+/)[0] + " " + time;
        }
        return "";
    }

    function handleParticipants(event, id) {
        setClickedValue(id);
        setOpenParticipantsDlg(true);
    }

    function handleClosePartcipantsDlg(value) {
        setOpenParticipantsDlg(false);
        setClickedValue(-1);
    }

    function handleShare(event, id) {
        const selectedEvent = _.find(eventList, ["id", id]);
        setClickedEventValue(selectedEvent);
        setClickedValue(id);
        setOpenShareDlg(true);
    }

    function handleCloseShareDlg(value) {
        setOpenShareDlg(false);
        setClickedValue(-1);
    }

    const onHandleChange = e => {
        setSearchKey(e.target.value);
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Container
                        beforeCodeTitle={intl.formatMessage({
                            id: "EVENTS.CONTAINER.TITLE"
                        })}
                        handleSearch={onHandleChange}
                        handleAdd={handleAdd}
                    >
                        <div className="kt-section">
                            <div className="kt-section__content">
                                <div className={classes.root}>
                                    <Paper className={classes.paper}>
                                        <EnhancedTableToolbar numSelected={selected.length} />
                                        <div className={classes.tableWrapper}>
                                            <Table
                                                className={classes.table}
                                                aria-labelledby="tableTitle"
                                                size="medium"
                                            >
                                                <EnhancedTableHead
                                                    numSelected={selected.length}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onSelectAllClick={handleSelectAllClick}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={eventList.length}
                                                    headRows={headRows}
                                                />
                                                <TableBody>
                                                    {stableSort(
                                                        searchArray(
                                                            eventList,
                                                            searchKey,
                                                            searchable
                                                        ),
                                                        getSorting(order, orderBy)
                                                    )
                                                        .slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage + rowsPerPage
                                                        )
                                                        .map((row, index) => {
                                                            const isItemSelected = isSelected(
                                                                row.id
                                                            );
                                                            const labelId = `enhanced-table-checkbox-${index}`;

                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    aria-checked={isItemSelected}
                                                                    tabIndex={-1}
                                                                    key={row.id}
                                                                    selected={isItemSelected}
                                                                >
                                                                    <TableCell padding="checkbox">
                                                                        <Checkbox
                                                                            checked={isItemSelected}
                                                                            onClick={event =>
                                                                                handleCheckClick(
                                                                                    event,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                            inputProps={{
                                                                                "aria-labelledby": labelId
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div className="kt-user-card-v2 kt-user-card-v2--uncircle">
                                                                            <div className="kt-user-card-v2__pic">
                                                                                <img
                                                                                    src={
                                                                                        row.pic
                                                                                            ? process
                                                                                                  .env
                                                                                                  .REACT_APP_FILE_URL +
                                                                                              row.pic
                                                                                            : toAbsoluteUrl(
                                                                                                  "/media/logos/logo-trans.png"
                                                                                              )
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="">
                                                                                {row.title}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-2 d-block d-sm-none">
                                                                            {row.detail}
                                                                        </div>
                                                                        <div className="mt-2 d-block d-sm-none">
                                                                            {convertApiToLocalTime(
                                                                                row.start_datetime
                                                                            )}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell className="d-none d-sm-table-cell px-0">
                                                                        {row.detail}
                                                                    </TableCell>
                                                                    <TableCell className="d-none d-sm-table-cell px-0">
                                                                        {convertApiToLocalTime(
                                                                            row.start_datetime
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell className="d-none d-sm-table-cell px-0">
                                                                        {row.emitter_name
                                                                            ? row.emitter_name
                                                                            : ""}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IconButton
                                                                            aria-label="Edit"
                                                                            onClick={() =>
                                                                                handleEdit(row.id)
                                                                            }
                                                                        >
                                                                            <EditIcon />
                                                                        </IconButton>

                                                                        <IconButton
                                                                            aria-label="Delete"
                                                                            onClick={event =>
                                                                                handleDeleteClick(
                                                                                    event,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            aria-label="Delete"
                                                                            onClick={event =>
                                                                                handleJoin(
                                                                                    event,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <PhoneIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            aria-label="Add Users"
                                                                            onClick={event =>
                                                                                handleParticipants(
                                                                                    event,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <GroupAddIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            aria-label="Share"
                                                                            onClick={event =>
                                                                                handleShare(
                                                                                    event,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <ShareIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {emptyRows > 0 && (
                                                        <TableRow
                                                            style={{
                                                                height: 49 * emptyRows
                                                            }}
                                                        >
                                                            <TableCell colSpan={8} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={
                                                searchArray(eventList, searchKey, searchable).length
                                            }
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{
                                                "aria-label": "Previous Page"
                                            }}
                                            nextIconButtonProps={{
                                                "aria-label": "Next Page"
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </div>
                            </div>
                            <div>
                                <EventDlg
                                    eventId={clickedValue}
                                    admins={
                                        user.role === UserRole.SUPERADMIN
                                            ? admins
                                            : [
                                                  {
                                                      id: user.id,
                                                      name: user.name,
                                                      email: user.email
                                                  }
                                              ]
                                    }
                                    adminId={user.role === UserRole.ADMIN ? user.id : null}
                                    managers={managers}
                                    emitters={emitters}
                                    trans={trans}
                                    languages={languages}
                                    eventValue={clickedEventValue}
                                    open={openFormDlg}
                                    onClose={handleClose}
                                />
                            </div>
                            <div>
                                <EventParticipantsDlg
                                    eventId={clickedValue}
                                    open={openParticipantsDlg}
                                    onClose={handleClosePartcipantsDlg}
                                />
                                <EventShareDlg
                                    eventId={clickedValue}
                                    eventName={clickedEventValue.event_name}
                                    open={openShareDlg}
                                    onClose={handleCloseShareDlg}
                                />
                            </div>
                            <div>
                                <Dialog
                                    open={openDel}
                                    onClose={handleCloseDel}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {intl.formatMessage({
                                            id: "DIALOG.WARNING.TITLE"
                                        })}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {intl.formatMessage({
                                                id: "DIALOG.DEL.DESC"
                                            })}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseDel} color="primary">
                                            {intl.formatMessage({
                                                id: "EVENT.ACTION.CANCEL"
                                            })}
                                        </Button>
                                        <Button onClick={handleDelete} color="primary" autoFocus>
                                            {intl.formatMessage({
                                                id: "DIALOG.ACTION.CONTINUE"
                                            })}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}

EventList.propTypes = {
    eventList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired
        }).isRequired
    ).isRequired
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        eventList: state.events.eventList,
        emitters: state.emitters.emitterList,
        admins: state.admins.adminList,
        managers: state.managers.managerList,
        trans: state.trans.tranList,
        languages: state.languages.languageList
    };
};

const mapDispatchToProps = dispatch => ({
    getEvents: () => dispatch(eventsStore.actions.getEvents()),
    removeEvent: eventId => dispatch(eventsStore.actions.removeEvent(eventId)),
    getAdmins: () => dispatch(adminsStore.actions.getAdmins()),
    getManagers: () => dispatch(managersStore.actions.getManagers()),
    getEmitters: () => dispatch(emittersStore.actions.getEmitters()),
    getTrans: () => dispatch(transStore.actions.getTrans()),
    getLanguages: () => dispatch(languagesStore.actions.getLanguages())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EventList));
