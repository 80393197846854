import { put, takeEvery } from "redux-saga/effects";
import {
    get_interpreter_events,
    get_interpreter_rooms,
    get_interpreter_event
} from "../../../crud/interpreters/index.crud";

export const actionTypes = {
    GET_INTERPRETER_EVENT: "GET_INTERPRETER_EVENT",
    GET_INTERPRETER_EVENT_SUCCESS: "GET_INTERPRETER_EVENT_SUCCESS",
    GET_INTERPRETER_EVENTS: "GET_INTERPRETER_EVENTS",
    GET_INTERPRETER_EVENTS_SUCCESS: "GET_INTERPRETER_EVENTS_SUCCESS",
    GET_INTERPRETER_ROOMS: "GET_INTERPRETER_ROOMS",
    GET_INTERPRETER_ROOMS_SUCCESS: "GET_INTERPRETER_ROOMS_SUCCESS"
};

export const actions = {
    getInterpreterEvent: id => ({
        type: actionTypes.GET_INTERPRETER_EVENT,
        event_id: id
    }),
    getInterpreterEventSuccess: event => ({
        type: actionTypes.GET_INTERPRETER_EVENT_SUCCESS,
        event: event
    }),
    getInterpreterEvents: () => ({ type: actionTypes.GET_INTERPRETER_EVENTS }),
    getInterpreterEventsSuccess: eventList => ({
        type: actionTypes.GET_INTERPRETER_EVENTS_SUCCESS,
        eventList: eventList
    }),
    getInterpreterRooms: () => ({ type: actionTypes.GET_INTERPRETER_ROOMS }),
    getInterpreterRoomsSuccess: roomList => ({
        type: actionTypes.GET_INTERPRETER_ROOMS_SUCCESS,
        roomList: roomList
    })
};

const initialInterpretersState = {
    eventList: [],
    roomList: [],
    isLoading: false
};

export const reducer = (state = initialInterpretersState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTERPRETER_EVENT: {
            return {
                ...state,
                isLoading: false
            };
        }
        case actionTypes.GET_INTERPRETER_EVENT_SUCCESS: {
            return {
                ...state,
                event: action.event,
                isLoading: true
            };
        }
        case actionTypes.GET_INTERPRETER_EVENTS_SUCCESS: {
            return {
                ...state,
                eventList: action.eventList
            };
        }
        case actionTypes.GET_INTERPRETER_ROOMS_SUCCESS: {
            return {
                ...state,
                roomList: action.roomList
            };
        }
        default:
            return state;
    }
};

export function* interpretersModuleSaga() {
    yield takeEvery(actionTypes.GET_INTERPRETER_EVENT, function* getEventSaga(action) {
        try {
            const response = yield get_interpreter_event(action.event_id);

            if (response && response.data) {
                const data = yield response.data;
                yield put(actions.getInterpreterEventSuccess(data.data));
            }
        } catch (err) {
            console.log(err);
        }
    });

    yield takeEvery(actionTypes.GET_INTERPRETER_EVENTS, function* getEventsSaga() {
        try {
            const response = yield get_interpreter_events();
            const data = yield response.data ?? [];
            yield put(actions.getInterpreterEventsSuccess(data.data));
        } catch {
            yield put(actions.getInterpreterEventsSuccess([]));
        }
    });

    yield takeEvery(actionTypes.GET_INTERPRETER_ROOMS, function* getRoomsSaga() {
        const response = yield get_interpreter_rooms();
        const data = yield response.data ?? [];
        yield put(actions.getInterpreterRoomsSuccess(data.data));
    });
}
