/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as dashboardStore from "../store/ducks/dashboard.duck";

function LatestUpdatesDevices(props) {
    const { devices, getStatDevices, intl } = props;

    useEffect(() => {
        getStatDevices();
    }, [getStatDevices]);
    return (
        <>
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            {intl.formatMessage({
                                id: "DASHBOARD.DEVICES.TITLE"
                            })}
                        </h3>
                    </div>
                    {/* <PortletHeaderDropdown /> */}
                </div>
                <div className="kt-portlet__body">
                    <div className="kt-widget4">
                        {devices &&
                            devices.map((device, index) => (
                                <div key={index} className="kt-widget4__item">
                                    <span className="kt-widget4__icon">
                                        {device.device.toLowerCase().includes("desktop") ? (
                                            <i className="fas fa-desktop"></i>
                                        ) : (
                                            <i className="fas fa-mobile-alt"></i>
                                        )}
                                    </span>
                                    <a className="kt-widget4__title">{device.device}</a>
                                    <span className="kt-widget4__number kt-font-success">
                                        {device.amount}
                                    </span>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        // user: state.auth.user,
        // isEndMeeting: state.customerReducer.isEndMeeting,
        devices: state.dashboard.devices
    };
};

const mapDispatchToProps = dispatch => ({
    // endMeetingSuccess: () => dispatch(eventsStore.actions.endMeetingSuccess()),
    getStatDevices: () => dispatch(dashboardStore.actions.getStatDevices())
    // updateRoomList: (roomList) => dispatch(eventsStore.actions.updateRoomList(roomList)),
    // addStat: (data) => dispatch(eventsStore.actions.addStat(data)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LatestUpdatesDevices));
