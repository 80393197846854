import { apiGetRequest } from "../helper";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const EMITTERS_URL = BASE_URL + "emitter";
export const SCHEDULES_URL = BASE_URL + "emitter/schedule_list";
export const SCHEDULE_URL = BASE_URL + "emitter/schedules";

export function get_emitter_schedules() {
    return apiGetRequest(SCHEDULES_URL);
}

export function get_schedule(id) {
    return apiGetRequest(SCHEDULE_URL + `/${id}`);
}

export function get_emitter_events() {
    return apiGetRequest(EMITTERS_URL + "/event_list");
}

export function get_emitter_event(id) {
    return apiGetRequest(EMITTERS_URL + `/events/${id}`);
}

export function get_emitter_rooms() {
    return apiGetRequest(EMITTERS_URL + "/room_list");
}

export function get_room(id) {
    return apiGetRequest(EMITTERS_URL + `/rooms/${id}`);
}
