/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { TablePagination } from "@material-ui/core";

import * as dashboardStore from "../store/ducks/dashboard.duck";

const useStyles = makeStyles(theme => ({
    statContainer: {
        maxWidth: "70%",
        flexWrap: "wrap"
    },
    statItem: {
        paddingRight: "3rem",
        paddingBottom: "2rem"
    }
}));
function EventDetails(props) {
    const { eventsDetails, getStatEventsDetails, intl } = props;
    const classes = useStyles();

    useEffect(() => {
        getStatEventsDetails();
    }, [getStatEventsDetails]);

    useEffect(() => {}, [eventsDetails]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    function convert2min(value) {
        if (value >= 60) {
            const mins = Math.floor(value / 60) + " mins";
            const seconds = Math.floor(value % 60) + " s";
            return mins + " " + seconds;
        } else if (value < 0) {
            return "0 s";
        }
        return Math.floor(value) + " s";
    }

    return (
        <>
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            {intl.formatMessage({ id: "DASHBOARD.CONNECTIONS.TITLE" })}
                        </h3>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    <div className="kt-widget5">
                        {eventsDetails &&
                            eventsDetails
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((eventDetail, index) => (
                                    <div key={index} className="kt-widget5__item ">
                                        <div className="kt-widget5__content">
                                            <div className="kt-widget5__section">
                                                <a className="kt-widget5__title">
                                                    {eventDetail.title}
                                                </a>
                                                <p className="kt-widget5__desc">
                                                    {eventDetail.detail}
                                                </p>
                                                <div className="kt-widget5__info">
                                                    {/* <span>Participants: </span>
                      <span className="kt-font-info">{5}</span> */}
                                                    <span>
                                                        {intl.formatMessage({
                                                            id: "DASHBOARD.CONNECTIONS.FIELD.DATE"
                                                        })}
                                                        :{" "}
                                                    </span>
                                                    <span className="kt-font-info">
                                                        {eventDetail.start_datetime}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`kt-widget5__content ${classes.statContainer}`}
                                        >
                                            {eventDetail.event_rooms &&
                                                eventDetail.event_rooms.map((eventRoom, index) => (
                                                    <div
                                                        key={index}
                                                        className={`kt-widget5__stats ${classes.statItem}`}
                                                    >
                                                        <span className="kt-widget5__number">
                                                            {eventRoom.user_num === 0
                                                                ? 0
                                                                : convert2min(
                                                                      eventRoom.time /
                                                                          eventRoom.user_num
                                                                  )}
                                                        </span>
                                                        <span className="kt-widget5__sales">
                                                            {eventRoom.lang_label === ""
                                                                ? "Original"
                                                                : eventRoom.lang_label}
                                                        </span>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ))}
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={eventsDetails ? eventsDetails.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "Previous Page"
                        }}
                        nextIconButtonProps={{
                            "aria-label": "Next Page"
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        // user: state.auth.user,
        // isEndMeeting: state.customerReducer.isEndMeeting,
        eventsDetails: state.dashboard.eventsDetails
    };
};

const mapDispatchToProps = dispatch => ({
    // endMeetingSuccess: () => dispatch(eventsStore.actions.endMeetingSuccess()),
    getStatEventsDetails: () => dispatch(dashboardStore.actions.getStatEventsDetails())
    // updateRoomList: (roomList) => dispatch(eventsStore.actions.updateRoomList(roomList)),
    // addStat: (data) => dispatch(eventsStore.actions.addStat(data)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EventDetails));
