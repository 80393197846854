import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles, colors, Badge, IconButton, Fab } from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import PanToolIcon from "@material-ui/icons/PanTool";
import CallEndIcon from "@material-ui/icons/CallEnd";
import SpeakerIcon from "@material-ui/icons/Speaker";
import MessageIcon from "@material-ui/icons/Message";
import * as eventStore from "../../../store/ducks/event.duck";
import * as eventsStore from "../../../store/ducks/admins/index.duck";

const useStyles = makeStyles(theme => ({
    toolbox: {
        position: "absolute",
        bottom: props => (props.isToolbarShow ? 0 : -56),
        left: "auto",
        right: "auto",
        zIndex: "500",
        width: "100%",
        // backgroundImage: "linear-gradient(to top,rgba(0,0,0,.6),rgba(0,0,0,0))",
        backgroundColor: "#525050",
        display: "flex",
        justifyContent: "center",
        transition: "bottom .3s ease-in"
    },
    toolboxLeft: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        alignSelf: "center",
        width: "40%"
    },
    toolboxCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        width: "20%"
    },
    toolboxRight: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        alignSelf: "center",
        width: "40%"
    },
    shareScreen: {
        margin: 6,
        color: colors.common.white,
        minWidth: "40px !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        }
    },
    call: {
        margin: theme.spacing(1),
        backgroundColor: colors.red[800],
        color: colors.common.white,
        minWidth: "40px !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        },
        "&:hover": {
            color: colors.common.white,
            backgroundColor: colors.red[300]
        }
    },
    "@keyframes blinker": {
        from: { backgroundColor: colors.yellow["A400"] },
        to: { backgroundColor: "#0abb87" }
    },
    chat: {
        animationName: "$blinker",
        animationDuration: "1s",
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",
        "animation-fill-mode": "initial",
        "&:hover": {
            backgroundColor: colors.yellow[800]
        }
    },
    videoMute: {
        height: "40px !important",
        minWidth: "40px !important",
        borderRadius: "50% !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        }
    },
    mosaicButton: {
        padding: 10
    },
    volume: {
        color: colors.common.white,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 8,
            paddingRight: 8
        }
    },
    oscilloMeter: {
        position: "relative"
    },
    toolbarItem: {
        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },
    settingItem: {
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    }
}));

function Toolbar(props) {
    const {
        isMuted,
        isVideoOn,
        isChatBoxOpen,
        isNewMsg,
        isRaise,
        isInterpreter,
        disableMic,
        disableVideo
    } = props;
    const classes = useStyles(props);

    function handleMute(isMuted) {
        if (!disableMic) props.changeMute(isMuted);
    }

    function handleVideoOn() {
        if (!disableVideo) props.changeVideoOn(!isVideoOn);
    }

    function handleChat() {
        props.openChatBox(!isChatBoxOpen);
    }

    function handleLeave() {
        props.endMeeting();
    }

    function handleAudioOutputSetting() {
        props.setOpenAudioOutputSettingDlg(true);
    }

    function handleRaiseHand() {
        props.setIsRaise(!isRaise);
    }

    return (
        <div id="toolbox" className={classes.toolbox}>
            <div id="toolboxLeft" className={classes.toolboxLeft}>
                {isInterpreter && !props.disableChat && (
                    <Fab
                        color="secondary"
                        size="small"
                        aria-label="Chat"
                        className={classes.shareScreen}
                        onClick={handleChat}
                    >
                        <Badge
                            className={classes.badge}
                            color="primary"
                            invisible={!isNewMsg}
                            variant="dot"
                            overlap="rectangular"
                        >
                            <MessageIcon />
                        </Badge>
                    </Fab>
                )}
                {!isInterpreter &&
                    !props.disableAudioInput &&
                    (!isMuted ? (
                        <Fab
                            color="secondary"
                            size="small"
                            aria-label="MicOn"
                            className={classes.shareScreen}
                            onClick={() => handleMute(true)}
                            disabled={disableMic}
                        >
                            <MicIcon />
                        </Fab>
                    ) : (
                        <Fab
                            color="inherit"
                            size="small"
                            aria-label="MicOff"
                            className={classes.call}
                            onClick={() => handleMute(false)}
                            disabled={disableMic}
                        >
                            <MicOffIcon />
                        </Fab>
                    ))}
                {!props.disableVideoInput &&
                    (isVideoOn ? (
                        <Fab
                            color="secondary"
                            size="small"
                            aria-label="Video On"
                            onClick={handleVideoOn}
                            className={`${classes.videoMute} ${classes.shareScreen}`}
                            disabled={disableVideo}
                        >
                            <VideocamIcon />
                        </Fab>
                    ) : (
                        <Fab
                            color="inherit"
                            size="small"
                            aria-label="Video Off"
                            onClick={handleVideoOn}
                            className={`${classes.call} ${classes.videoMute} ${classes.shareScreen}`}
                            disabled={disableVideo}
                        >
                            <VideocamOffIcon />
                        </Fab>
                    ))}
                {!isInterpreter && !props.isEmitter && !props.disableRaiseHand && (
                    <IconButton
                        color="primary"
                        aria-label="Screen Size"
                        className={`${classes.shareScreen} ${classes.toolbarItem}`}
                        onClick={handleRaiseHand}
                    >
                        <PanToolIcon />
                    </IconButton>
                )}
            </div>
            <div id="toolboxCenter" className={classes.toolboxCenter}>
                {!isInterpreter && !props.isEmitter && !props.disableChat && (
                    <Fab
                        color="secondary"
                        size="small"
                        aria-label="Chat"
                        className={`${classes.shareScreen} ${isNewMsg ? classes.chat : ""}`}
                        onClick={handleChat}
                    >
                        <MessageIcon />
                    </Fab>
                )}
                <Fab
                    color="inherit"
                    size="small"
                    aria-label="CallEnd"
                    className={classes.call}
                    onClick={handleLeave}
                >
                    <CallEndIcon />
                </Fab>
            </div>
            <div id="toolbox-right" className={classes.toolboxRight}>
                <div className={classes.oscilloMeter}>
                    <IconButton
                        color="primary"
                        aria-label="Channel Audios"
                        className={classes.volume}
                        onClick={handleAudioOutputSetting}
                    >
                        <SpeakerIcon />
                    </IconButton>
                </div>

                {/* <Dropdown>
                    <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-basic"
                        drop={"up"}
                        navbar={false}
                    >
                        <IconButton
                            color="primary"
                            aria-label="More"
                            className={classes.volume}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        <Dropdown.Item
                            eventKey="2"
                            onClick={handleAudioOutputSetting}
                        >
                            <SpeakerIcon />{" "}
                            {intl.formatMessage({
                                id: "VIDEO.AUDIO_OUTPUT"
                            })}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const props = {
        user: state.auth.user,
        isMuted: state.event.isMuted,
        isVideoOn: state.event.isVideoOn,
        cameraDevices: state.event.cameraDevices,
        isChatBoxOpen: state.event.isChatBoxOpen,
        isNewMsg: state.event.isNewMsg,
        isRaise: state.event.isRaise,
        isMosaic: state.event.isMosaic,
        isCamera: state.event.isCamera,
        isShareOtherCamera: state.event.isShareOtherCamera,
        isOscilloMeter: state.event.isOscilloMeter,
        isGroupTranslator: state.event.isGroupTranslator,
        isToolbarShow: state.event.isToolbarShow
    };

    return props;
};

const mapDispatchToProps = dispatch => {
    const actions = {
        endMeeting: () => dispatch(eventStore.actions.endMeeting()),
        changeMute: isMuted => dispatch(eventStore.actions.changeMute(isMuted)),
        changeVideoOn: isVideoOn => dispatch(eventStore.actions.changeVideoOn(isVideoOn)),
        openChatBox: isChatBoxOpen => dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
        setOpenAudioInputSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
        setOpenAudioOutputSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
        setOpenVideoSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
        setIsRaise: isRaise => dispatch(eventStore.actions.setIsRaise(isRaise)),
        updateScreenMode: mode => dispatch(eventStore.actions.updateScreenMode(mode)),
        updateIsMosaic: isMosaic => dispatch(eventStore.actions.updateIsMosaic(isMosaic)),
        setIsCamera: isCamera => dispatch(eventStore.actions.setIsCamera(isCamera)),
        shareOtherCamera: isShareOtherCamera =>
            dispatch(eventStore.actions.shareOtherCamera(isShareOtherCamera)),
        setMuteAll: isMuteAll => dispatch(eventsStore.actions.setMuteAll(isMuteAll)),
        setIsOscilloMeter: isOscilloMeter =>
            dispatch(eventStore.actions.setIsOscilloMeter(isOscilloMeter)),
        setIsGroupTranslator: isGroupTranslator =>
            dispatch(eventStore.actions.setIsGroupTranslator(isGroupTranslator))
    };
    return actions;
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Toolbar));
