import { createPresenterEffect } from "../presenter";
import { createVirtualBackgroundEffect } from "../virtual-background/index";
const JitsiMeetJS = window.JitsiMeetJS;

export async function mutePresenterVideo(localTrack, cameraDeviceId, virtualBackground) {
    const height = localTrack.track.getSettings().height;
    const effect = await _createPresenterStreamEffect(height, cameraDeviceId, virtualBackground);
    return effect;
}

async function _createPresenterStreamEffect(
    height = null,
    cameraDeviceId = null,
    virtualBackground = null
) {
    const localPresenterVideo = await _createLocalPresenterTrack(
        { cameraDeviceId, virtualBackground },
        height
    );
    const effect = await createPresenterEffect(localPresenterVideo.stream);
    return effect;
}

async function _createLocalPresenterTrack(options, desktopHeight) {
    console.log(desktopHeight);
    const { cameraDeviceId, virtualBackground } = options;
    console.log("🚀 ~ _createLocalPresenterTrack ~ virtualBackground:", virtualBackground);
    const cameraHeights = [180, 270, 360, 540, 720, 1080, 2160];
    const proportion = 5;
    const result = cameraHeights.find(height => desktopHeight / proportion <= height) ?? 270;
    const constraints = {
        video: {
            // aspectRatio: 16 / 9,
            height: {
                ideal: result,
                max: result,
                min: result
            }
        }
    };
    const [videoTrack] = await JitsiMeetJS.createLocalTracks({
        cameraDeviceId,
        resolution: result.toString(),
        constraints,
        devices: ["video"]
    });

    if (virtualBackground) {
        const effect = await createVirtualBackgroundEffect(virtualBackground).catch(error => {
            console.log("🚀 ~ effect ~ error:", error);
        });
        await videoTrack.setEffect(effect);
    }

    return videoTrack;
}
