import React from "react";
import { CircularProgress, Button, colors, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(1),
        position: "relative"
    },
    buttonProgress: {
        color: colors.green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

export default function LoadingButton(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const timer = React.useRef();

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleButtonClick = () => {
        if (!loading) {
            props.onClick();
            setLoading(true);
            timer.current = window.setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    };

    return (
        <div className={classes.wrapper}>
            <Button
                variant="contained"
                color="primary"
                disabled={loading || props.loading}
                onClick={handleButtonClick}
            >
                {props.text}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
}
